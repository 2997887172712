.body {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container {
    max-width: 90%;
    width: 100%;
  }
  
  .container .steps {
    display: flex;
    width: 98%;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-left: 14px;
  }
  
  .steps .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    border: 4px solid #e0e0e0;
    color: #999;
    font-size: 15px;
    font-weight: 500;
    background: #fff;
    z-index: 1;
  }
  .steps-stage{
    display: flex;
    gap:40%;
  }
  
  .circle.active {
    border-color: #36aebe;
    color: #fff;
    background: #36aebe;
    transition: all 1000ms ease;

  }
  
  .steps .progress-bar {
    position: absolute;
    height: 15px;
    width: 100%;
    background: #e0e0e0;
    z-index: 0;
    border-radius: 5px;
    opacity: 0.4;
  }
  
  .progress-bar .indicator {
    position: absolute;
    height: 100%;
    width: 30%;
    background: #36aebe;
    transition: all 1000ms ease;
    opacity: 0.8;
    border-radius: 5px;
  }
  
  .active-menu{
    color: #36aebe;
  }