/**
Developer Business logic classes

*/


.validation_red {
  color: #F44336 !important
}

.validation_yellow {
  color: #FFC107 !important
}

.validation_green {
  color: #4CAF50 !important
}


.title_issues{
  background-color: #eee; padding:11px 15px 11px 18px; text-align: center; margin-left:-10px; border-right:2px solid #fff; margin-right:10px;
}

.validation_yellow_bg {
  background-color: #FFC107 !important;
  color: #fff !important;
}

.validation_red_bg {
  background-color: #F44336 !important;
  color: #fff !important;
}

.validation_green_bg {
  background-color: #4CAF50 !important;
  color: #fff !important;
}

.validationRedBorder {
  border-top: 2px solid #F44336 !important;
}

.validationYellowBorder {
  border-top: 2px solid #FFC107 !important;
}

.validationGreenBorder {
  border-top: 2px solid #4CAF50 !important;
}