.bar:hover{
    fill: #5B6770;
}

text {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: black;
}
.axis {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 13px;
}
.axis path,
.axis line {
    fill: none;
    stroke: #E0E0E0;
    stroke-width: 1px;
    shape-rendering: crispEdges;
}

.axis-title{
    fill: #e0e0e0;
    letter-spacing: 1px;
}
.toolTip {
    pointer-events: none;
    position: absolute;
    display: none;
    min-width: 50px;
    height: auto;
    background: none repeat scroll 0 0 #ffffff;
    padding: 9px 14px 6px 14px;
    border-radius: 2px;
    text-align: center;
    line-height: 1.3;
    color: #5B6770;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, .15);
}
/* .toolTip:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid white;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -12px;
} */
.toolTip span {
    font-weight: 500;
    color: #081F2C;
}	
.gridline path,
.gridline line{
    fill: none;
    stroke: #E0E0E0;
    stroke-width: 1px;
    shape-rendering: crispEdges;
}