/**==================| Main Css |====================================================================**/

/** General **/
body,
html {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  scrollbar-color: #36aebe #f1f1f1;
}

#root {
  height: 100%;
  font-size: 0.875rem !important;
}

.width100per,
.w-100 {
  width: 100% !important;
}

.p-0-24 {
  padding: 0px 24px !important;
}

.p-b-t-4 {
  padding-top: 4px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-t-12 {
  padding-top: 12px !important;
}

.p-t-24 {
  padding-top: 24px !important;
}

.p-t-36 {
  padding-top: 36px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-t-4 {
  padding-top: 4px !important;
}

.p-t-4-HO {
  padding-top: 2px !important;
}

.p-b-0 {
  padding-bottom: 0 !important;
}

.p-t-b-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.m-t-6 {
  margin-top: 6px !important;
}

.m-t-14 {
  margin-top: 14px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-b-15 {
  margin: 15px 0px !important;
}

.p-t-b-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.p-t-b-12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.m-l-8 {
  margin-left: 8px !important;
}

.m-r-8 {
  margin-right: 8px !important;
}

.p-0 {
  padding-top: 0;
}

.p-16 {
  padding: 16px !important;
}
.p-b-unset {
  padding-bottom: unset !important;
}
div .m-t-0 {
  margin-top: 0px !important;
}

td > .m-t-b-4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.m-b-12 {
  margin-bottom: 12px !important;
}

.p-l-20 {
  padding-left: 20px !important;
}

.p-t-20 {
  padding-top: 20px;
}

.p-l-r-8 {
  padding: 0px 8px !important;
}

.p-l-r-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.p-l-r-16 {
  padding: 0px 16px !important;
}

.p-l-12 {
  padding-left: 12px !important;
}

.p-l-r-6 {
  padding: 0px 6px !important;
}

.p-12 {
  padding: 12px !important;
}

.p-r-24 {
  padding-right: 24px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
.align-self-center {
  align-self: center;
}

.noPadding {
  padding: 0px !important;
}

.roundCircle > span {
  background-color: #36aebe;
}

.roundCircle1 {
  background-color: #36aebe;
  padding-left: 6px;
  padding-top: 6px;
  border-radius: 14px;
  height: 25px;
  width: 25px;
  margin-right: 6px;
}
/* .anchorIconLink  .roundCircle1 {
    background-color: #FBAA92;
    padding-left: 6px;
    padding-top: 6px;
    border-radius: 14px;
    height: 25px;
    width: 25px;
    margin-right: 6px;
}

.anchorIconLink  .loanAddIcons  {
    background-color: #FBAA92!important;
    padding-left: 6px;
    padding-top: 6px;
    border-radius: 14px;
    height: 25px;
    width: 25px;
    margin-right: 6px;
} */

.noMargin {
  margin: 0px !important;
}

.marginAuto {
  margin: auto !important;
}

.overFlowAuto {
  overflow: auto;
}

.tableContainerScroll {
  width: 100%;
  position: relative;
  overflow-x: auto;
}

.tableMinFullWidth table {
  min-width: 100% !important;
  margin-top: 10px !important;
}

/* Scrollbar Style Started */

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Thumb */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #36aebe;
  border-radius: 5px;
}

/* Handle on hover */

/* ::-webkit-scrollbar-thumb:hover {
    background: #36aebe;
} */

/* Scrollbar Style Ended */

/**** Login ***/

.banner_bg {
  background-image: url("./assets/img/banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}

.banner_txt {
  height: 300px;
}

.login_block {
  padding: 35px;
  text-align: center;
  background-color: #fff;
  margin: 0 auto;
}

.h-100 {
  height: 100%;
}

.h-100vh {
  height: 100vh;
}

.p-r {
  position: relative;
}

/* .banner_block{
    padding: 35px;
} */

.banner_block {
  text-align: left;
  padding-left: 50px;
  padding-right: 50px;
  /* line-height: 5px !important; */
  padding-top: 100px !important;
  width: 100%;
  height: 100%;
}

.banner_block > .headlines {
  font-weight: 300;
}

.preview_banner_block {
  text-align: left;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 12px !important;
  height: 100%;
}

.preview_banner_block > .headlines {
  font-weight: 300;
}

.preview_banner_block > h1 {
  color: #fff;
}

.banner_content_preview {
  padding: 12px;
  overflow-wrap: break-word;
  overflow-y: auto;
}

.banner_content {
  padding: 12px;
  overflow-wrap: break-word;
  /* max-height: 70vh; */
  overflow-y: auto;
}

.login_form {
  height: 170px;
  margin: 40px 0px !important;
}

.headlines {
  line-height: 30px !important;
}

.hfc {
  height: 100px !important;
  /* width: 225px; */
  /* padding-top: 20px; */
  /* margin-left: 20px */
}

.soh {
  height: 78px !important;
  /* width: 162px; */
  /* margin-left: 100px; */
}

.commerceLogo {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.banner_block > h1 {
  color: #fff;
  /* font-weight: 400; */
}

.loginbottomcounties {
  padding-bottom: 50px;
}

.btn_outline {
  border: 1px solid #fff !important;
  color: #fff !important;
}

.btn_outline:hover {
  background-color: #fff !important;
  color: #222 !important;
}

.footer_links {
  position: absolute;
  bottom: 10px;
  text-align: left;
  width: 100%;
  padding-left: 50px;
  right: 0;
  color: #fff;
  font-size: 14px;
}

.footer_links > h1 {
  text-align: center;
}

.footer_links > p > a {
  color: #fff;
  text-decoration: none;
}

.footer_links > p > a:hover {
  color: #36aebe;
}

.btn_outline:hover .HelpTopic h2 {
  font-weight: 300;
}

.helpPadding {
  padding-top: 15px;
}

.HelpTopic,
.HelpSubTopic {
  letter-spacing: 0;
}

.HelpViewUrl {
  text-align: center;
  overflow: hidden !important;
}

/* .HelpTopicsLink h3 {
    font-weight: 200;
} */

.expansionPanel .fa {
  padding-left: 20px;
  color: black;
}

.expansionPanel {
  width: 90%;
  cursor: pointer;
  color: #36aebe !important;
  border-bottom: 1px solid #eee;
}

.helpAutocomplete div[class*="css-11unzgr"] {
  font-size: 11;
  overflow: auto;
  min-height: auto;
  max-height: 180px;
}

.readonlyorgAutocomplete div[class="css-11unzgr"] {
  overflow: auto;
  min-height: 100px;
  max-height: 150px;
}

.confidentialProject {
  margin-top: 20px;
  margin-left: 3px;
}

.banner_container {
  position: relative;
}

.login_block2 {
  width: 300px;
}

.login_ip_field {
  width: 300px;
}

.login_block_container {
  width: 300px !important;
  margin: 0 auto;
}

.editorClassName {
  border: 1px;
  border-style: solid;
  border-color: #ddd;
  min-height: 150px !important;
  max-height: 200px;
  overflow: auto !important;
}

/**** Header Logo Image ****/

.logo_img {
  width: 150px;
}

/** content wrapper **/

/* #contwrap{ height: calc(100% - 50px); padding-top:0px!important;  }
.card_wrap{ height: calc(100% - 50px );} */

.dashboradCard {
  min-height: 350px;
}

/* .dashboradCard .svgloader {height: 80px; width: 80px;} */

.parentCard {
  overflow: auto !important;
}
.occupantsView {
  height: 100%;
  width: 100%;
}
/* inner scroll for occupants */
.occupantsView .parentCard {
  overflow: hidden !important;
  height: 100%;
  width: 100%;
}
.occupantsView .occupantsTab {
  overflow: auto !important;
  height: 100%;
  padding-bottom: 60px;
}

.usersView {
  height: 100%;
  width: 100%;
}
/* inner scroll for users */
.usersView .parentCard {
  overflow: hidden !important;
}
.usersView .userEdit {
  overflow: auto !important;
  height: calc(100% - 47px);
}

.childCard {
  /* margin: -4px -12px !important; */
  overflow: auto !important;
}
.reports .childCard {
  overflow: hidden !important;
}

/* .childCard >div{
    overflow-y: hidden;
} */
/* .childCard .nocontent {
    top: 75% !important;
} */

/* .tableComponent{ position: relative;} */

.childCard .tableComponent {
  padding: 0px 12px;
}

.loaderRow {
  padding: 0px !important;
  height: 2px !important;
  border: none !important;
}

.loaderRow > th {
  padding: 0px !important;
  border: none !important;
  position: relative;
}

.linearProgressContainer {
  width: 100%;
  position: absolute;
  top: -1px;
}

.tblMoreInfoIconCel {
  padding: 0px !important;
  text-align: right !important;
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center;
  height: 26px;
  width: 56px;
}

.tblMoreInfoIconCel span {
  margin-left: 5px;
}

.tblMoreInfoIconCel .activeCls {
  color: red;
}

.tblMoreInfoIconCel .roleCls {
  color: #fbab92;
}

.tblMoreInfoIconCel .homeCls {
  color: #fbab92;
  width: 13px;
  height: 13px;
}
.roleCls visible {
  height: 100%;
  width: 100%;
  margin-top: 8px;
}
.visible {
  visibility: visible;
}

.hidden {
  display: none !important;
}

.tosDialog > div:nth-child(2) {
  max-width: 800px !important;
}
.tosDialog .MuiDialog-paperScrollPaper {
  max-height: calc(100% - 80px) !important;
  margin: 0 !important;
}

/*** Side Menu Minimize Size ***/

/*.sidemenu_close { width: 62px !important;}*/

.chart_block {
  text-align: center;
  margin: 0 auto;
  min-height: 250px;
}

.summary_card {
  min-height: 100px;
  vertical-align: middle;
}

.d_down_report {
  /* background-color: #f4f4f4; */
  padding: 8px;
}
.d_down_reports {
  /* background-color: #f3f3f3; */
  padding: 8px;
}

.profilephoto {
  padding: 0;
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: block;
  max-width: 330px;
  max-height: 330px;
}

.uploadwrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  outline: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profilephoto > img {
  /* position: absolute;
    top: 0; */
  border: 1px solid #ddd;
  text-align: center;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.profilephoto .uploadphoto {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  color: #fff;
  text-align: center;
}

.profilephoto .uploadphoto:hover {
  background-color: #36aebe;
}

.pagination-block {
  font-size: 13px;
  margin: 3px 0px;
  /* padding: 0 6px;  */
  text-align: left;
}

.pagination-block-Prev-Next {
  font-size: 20px;
  margin: 0px 0px;
  margin-top: 20px;
}

.pagination-block-Prev-Next .pagination_ul {
  margin-top: 0 !important;
}

.pagination_ul {
  margin: 0;
  padding: 0;
  list-style: none;
  float: right;
  margin-top: 6px;
  /* color:#909090; */
}

.pagination_ul > li {
  float: left;
}
.globalSearchCategory {
  display: flex;
}
.pagination_ul > li > a {
  margin: 0 2px;
  border-radius: 50%;
  line-height: 30px;
  width: 30px;
  height: 30px;
  display: block;
  text-align: center;
  cursor: pointer;
}

.pagination_ul > li.active > a {
  background-color: #fbab92;
  color: #fff;
}

.pagination_ul > li > a:hover {
  background-color: #fbab92 !important;
  color: #fff !important;
}

.pagination_ul > li.isDisablePagination > a:hover {
  background-color: #fff !important;
  color: #fff !important;
}

.pagination_ul > li > a:hover > svg > g > g > path {
  fill: #fff !important;
}

.pagination_ul > li.isDisablePagination > a:hover > svg > g > g > path {
  fill: #808080 !important;
}

.loanPagination > li.isDisablePagination > a:hover > svg > g > g > path {
  fill: #808080 !important;
}

.pagination_ul > li.disabled > a {
  background-color: #fff;
  color: #fff !important;
}

.pagination_ul > li.active.disabled > a {
  background-color: #80808052 !important;
  color: #fff !important;
}

.loanPagination > li.active.disabled > a {
  background-color: #80808052 !important;
  color: #fff !important;
}

.button.innerbtn {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 11px;
  min-height: 30px;
}

.tab_inner_heading {
  background-color: #fafafa;
  padding: 10px 20px;
}

.p-24 {
  padding: 24px;
}

.MuiListItem-root-119:hover {
  color: #36aebe;
  background-color: #ecf7f9 !important;
}

.MuiListItem-root-119:hover > i {
  color: #36aebe;
}

.MuiListItem-root-119:hover
  > .MuiListItemText-root-130
  > .MuiListItemText-primary-133 {
  color: #36aebe !important;
}

.delete_icon {
  font-size: 14px !important;
}

.submit_icon > span {
  margin-left: 60px !important;
  margin-right: 40px;
  font-size: 14px !important;
}

.unsubmit_icon > span {
  margin-left: -250px !important;
  font-size: 14px !important;
}

.delete_icon_stickyTable {
  font-size: 14px !important;
  height: 10px !important;
}

.delete_icon_Help {
  font-size: 16px !important;
}

.edit_icon_Help {
  font-size: 16px !important;
}

.MuiListItem-active {
  color: #36aebe !important;
  background-color: #ecf7f9 !important;
}

.MuiListItem-active .MuiListItemIcon-root svg path {
  fill: #36aebe !important;
  background-color: #ecf7f9 !important;
}
.MuiListItem-active #mortgage-loan path {
  fill: #2f67cc !important;
}
.MuiListItem-active.Loan.Projects {
  color: #2f67cc !important;
}

/* for help content*/

a.helpcontainer {
  color: #36aebe !important;
  background-color: #ecf7f9 !important ;
}

.MuiListItem-active-help {
  background: #ecf7f9 !important;
}

.Homeownership.MuiListItem-active {
  color: #2f67cc !important;
  background-color: #eff4fd !important;
}
.DashboardHO.MuiListItem-active {
  color: #2f67cc !important;
  background-color: #eff4fd !important;
}
.loanColorUpdate {
  background-color: #2f67cc !important ;
}
/* .MuiListItem-active .MuiListItemIcon-root svg #Path_63 {
    fill: red !important;
    background-color: #ecf7f9 !important;
} */

.MuiListItem-active i {
  color: #36aebe;
}
.add-rows > svg {
  margin-right: 10px;
}
.loanAddIcon {
  color: #2f67cc !important;
}

.linkCell {
  color: #36aebe !important;
  cursor: pointer;
}
.loanLinkCell {
  color: #2f67cc !important;
  cursor: pointer;
}
.funderLink .linkCell {
  color: #2f67cc !important;
  cursor: pointer;
}

.copy_icon {
  font-size: 14px !important;
}

.title_subtext {
  font-size: 11px;
  text-transform: initial;
  padding-left: 0px;
  font-style: italic;
  color: #999;
  opacity: 0.7;
  display: block;
}

.title_subtext > b {
  color: #222;
}

.tableactions {
  display: flex !important;
  justify-content: flex-end !important;
}

.actions-end {
  display: flex !important;
  justify-content: flex-end !important;
}

.actions-center {
  display: flex !important;
  justify-content: center !important;
}

.action-center-bulkdata {
  display: flex !important;
  justify-content: end !important;
}

.XmlFlexEnd {
  display: flex !important;
  justify-content: flex-end !important;
}

.search-not-found-left {
  position: absolute;
  text-align: left;
  margin-left: 50px;
  top: 52%;
  color: #ccc;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0;
}

.search-not-found-right {
  position: absolute;
  text-align: right;
  margin-left: 50px;
  top: 52%;
  color: #ccc;
  font-weight: bold;
  font-size: 22px;
  letter-spacing: 0;
}

.nocontent {
  /* position: absolute;
    left: 0;
    right: 0;
    top: 52%; */
  align-items: center;
  padding: 12px 0;
  text-align: center;
  color: #ccc;
  font-weight: bold;
  font-size: 32px;
  letter-spacing: 0;
  /* padding-top: 200px; */
}

.nocontent-inside {
  text-align: center;
  margin-top: 25px;
  color: #ccc;
  font-weight: bold;
  font-size: 25px;
  letter-spacing: 0;
}

.occupancyRate {
  margin: 135px;
  text-align: center;
  color: #ccc;
  font-weight: bold;
  font-size: 25px;
}

.autocomple_dropdwn {
  color: rgba(0, 0, 0, 0.54);
  margin-right: 9px;
  position: absolute;
  right: 0px;
  top: 8px;
  text-align: right;
}

.loading_block {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 999;
  content: "";
}

.required label:after {
  color: #e32;
  content: " *";
  display: inline;
}

/* .disabled p {
    color: rgba(0, 0, 0, 0.38);
} */

.projectsummary_card {
  overflow: visible !important;
}

.tenantactivity_card {
  overflow: visible !important;
}

.table2_card {
  overflow: visible !important;
}

.table3_card {
  overflow: visible !important;
}

.dashboardViewMoreBtn {
  top: 6px;
}

.section_title {
  background-color: #eee;
  color: #222;
  font-size: 16px;
  padding: 10px;
  font-weight: bold;
  margin: 0;
  width: 100%;
}

.section_title_edit {
  background-color: #eee;
  color: #222;
  font-size: 13px;
  padding-top: 4px;
  font-weight: 500;
  margin: 0;
  width: 100%;
  overflow: hidden;
}

.setAsideLIH {
  padding-bottom: 4px !important;
}

.ReportDownloadBox {
  background-color: #eee;
  color: #222;
  font-size: 16px;
  padding: 24px;
  font-weight: bold;
  margin: 0;
  width: 100%;
}

.ReportDownloadBox_edit {
  background-color: #eee;
  color: #222;
  font-size: 16px;
  padding: 0px;
  margin-top: 10px !important;
  margin-left: 5px;
  margin-right: 15px;
  font-weight: bold;
  margin: 0;
  width: 100%;
  overflow: hidden;
}

.edit_title {
  background-color: #fff !important;
  color: #36aebe;
}

.expansion_edit_br {
  border-right: 2px solid #eee !important;
}

.report_level_title {
  color: #36aebe;
  font-size: 18px;
  margin-top: 10px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.report_level_title_HO {
  color: #36aebe;
  font-size: 18px;
  margin-top: 10px;
  font-weight: bold;
  margin: 0;
}

.configurationContainer {
  border: 1px solid #eee;
}

.configurationContainer .configurationContent {
  background-color: #f4f4f4 !important;
  padding: 0 0 0 16px;
  font-size: 13px;
}

.configurationContainer .configurationMenu > div:first-child {
  overflow: auto;
}

.configurationContainer .configurationContent > div:first-child {
  overflow-y: auto;
  overflow-x: hidden;
}

.configurationContainer .navBar > a {
  border-bottom: 1px solid #eee;
  font-size: 13px;
}

/* .helpContainer { */
/* border: 1px solid #eee;
    padding: 12px; */
/* } */

.helpMenu {
  padding-right: 12px;
  border: 1px solid rgb(238, 238, 238);
}

.helpContainer .helpContent {
  background-color: #ffffff !important;
  font-size: 13px;
}

.helpContainer .helpMenu > div:first-child {
  overflow: auto;
}

.helpContainer .helpContent > div:first-child {
  overflow-y: auto;
  overflow-x: hidden;
}

.helpContainer .navBar > a {
  border-bottom: 1px solid #eee;
  font-size: 13px;
}

.loader {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 45%;
}

.svgloader {
  width: 150px;
  height: 150px;
  margin: 0 auto;
}

.pagination-block .svgloader {
  width: 40px;
  height: 40px;
}

.svgloader > svg {
  width: 100% !important;
  height: 100% !important;
}

.st0 {
  fill: none;
  stroke: #36aebe;
  stroke-width: 7;
  stroke-miterlimit: 10;
  stroke-dasharray: 30.1056, 30.1056;
}

.st1 {
  fill: #36aebe;
}

.greyLoader .st1 {
  fill: #ddd !important;
}

.greyLoader .st0 {
  stroke: #ddd !important;
}

.st2 {
  fill: #ffffff;
}

.searchip {
  border: 1px solid #ccc !important;
  width: 100% !important;
  padding-left: 10px !important;
  background: #f7f7f7;
}

.searchipmer {
  /* border: 1px solid #ccc !important; */
  width: 100% !important;
  padding-left: 10px !important;
  margin-bottom: 18px !important;
  /* background: #f7f7f7; */
}

.toorgmerge {
  padding-top: 22px !important;
}

.searchip *::before {
  display: none !important;
}

.textBox--filterAttributes {
  padding: 5px 3px !important;
  margin-bottom: 1px !important;
}

/* Border for input fields*/

.textBox--borderWidth {
  border: 1px solid #ccc !important;
  padding: 5px 3px !important;
  width: 80px;
}

.textBox--borderedWidth input {
  border: 1px solid #ccc !important;
  padding: 8px 4px !important;
  width: 80px !important;
}

.textBox--borderedDate,
.textBox--borderedDateWidth {
  border: 1px solid #ccc !important;
  padding: 3px 4px !important;
}

.textBox--borderedDateWidth,
.HHI-date {
  width: 140px !important;
}

.autocomplete-border .autoComplete {
  border: 1px solid #ccc !important;
  padding: 2px 4px !important;
  min-height: 38px;
}

.textBox--bordered .MuiInput-input {
  border: 1px solid #ccc !important;
  width: 100% !important;
  /* min-height: 52px; */
}

.textBox--bordered input {
  padding: 5px 3px !important;
}

.textBox--bordered .MuiInputBase-multiline,
.MuiInputBase-multiline {
  padding: 3px !important;
}

/* .input--disabled{
    background-color: #e7e3e362;
} */

.textBox--bordered *::before {
  display: none !important;
}

.anchorIconLinkRight {
  text-align: right;
  /* top: 5px; */
  position: relative;
  cursor: pointer;
}
/* .anchorIconLinkRightHO{
    text-align: right;
    top: 5px;
    position: relative;
    cursor: pointer;
} */
.addRow {
  top: 5px;
}

.anchorIconLink {
  text-align: left;
  /* top: 5px; */
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 12px;
}

.anchorIconLink i,
.anchorIconLink svg,
.anchorIconLinkRight i {
  margin-right: 0.5em;
  color: #36aebe;
}

/* .anchorIconLinkRightHO i{
    margin-right: 0.5em;
    color: #2f67cc;
} */

.anchorIconLink a,
.anchorIconLinkRight a {
  font-size: 14px;
  color: #36aebe;
  display: flex;
  align-items: center;
}

/* .anchorIconLink a,
.anchorIconLinkRightHO a {
    font-size: 14px;
    color:#2f67cc;
    display: flex;
    align-items: center;
} */
.household-info-table-pageactions {
  margin-bottom: 15px;
}
/* .household-info-table{
    overflow-x: auto;
} */
.icon-height-21 i {
  height: 21px;
  width: 21px;
  font-size: 21px;
}

.subpage {
  border-bottom: 1px solid #e4e4e4;
  background-color: #f4f4f4;
  padding: 0px 12px;
}

.page-header {
  padding: 0 0 3px 0;
}

.page-headers{
  padding: 0 0 25px 0;
}

.subpage.white {
  background-color: #fff;
  border-color: #fff;
}

.text-button {
  padding: 4px !important;
  min-width: auto !important;
  min-height: auto !important;
}

.required-header,
.normal-header {
  /* border-bottom: 1px solid rgba(224, 224, 224, 1); */
  font-size: 15px;
  font-weight: 500;
}

.required-header::after {
  content: " *";
  color: #e32;
}

.scrollbar-thumb {
  background-color: #36aebe !important;
}

.ddTooltip {
  position: absolute;
  top: 20px;
  color: #fbab92;
}

.ddTooltip_table5 {
  position: absolute;
  top: 15px;
  /* right: 188px; */
  color: #fbab92;
}

.posRelative {
  position: relative;
}

/* button .fa {
    padding-top: 3px;
} */

/* .reports .childCard {padding: 24px !important;} */

/* .reportTab>div>div>div>button {
    border-top: 2px solid #36aebe !important;
} */
/* .reports {
    padding-top: 12px;
} */

.reportTab > div > div > span {
  top: 0 !important;
  height: 0.2em !important;
  background-color: unset !important;
}

.reportTab > div > div > div > button:last-child {
  border-right: none;
}

/* .headerUserLoan.Mui-selected{
    background-color: #ECF7F9 !important;
} */

.reportTabSelected {
  background-color: #ecf7f9 !important;
}

.reportTab > div > div > div > button > span > span > span {
  font-size: 14px !important;
}

/* Explore Page CSS */

.explore .MuiGrid-spacing-xs-3 {
  width: unset;
  margin: 0 0 0 0;
  height: 100%;
}
.explore .MuiGrid-spacing-xs-3 > .MuiGrid-item {
  height: 100% !important;
}

.explore
  .MuiGrid-spacing-xs-3
  > .MuiGrid-item
  > .explore
  .MuiGrid-spacing-xs-3
  > .MuiGrid-item {
  height: 100% !important;
}

.exploreHeader {
  border-bottom: 1px solid #eee;
  height: 35px;
}

.exploreHeader h4,
.exploreSubHeader {
  font-weight: 450;
  letter-spacing: 0;
}

.Filter {
  background-color: #f7f7f7;
}

.Filter h4 {
  font-weight: 400;
}

tr.footer-row,
tr.children-row,
tr.children-row div[class*=" PageActions"] {
  height: 20px !important;
}

tr.footer-row td,
tr.children-row td {
  text-align: right;
  font-weight: bold;
  border: none !important;
}

.fieldWidth12 tr > td > .textBox--bordered {
  width: 100%;
}

.mtop12 {
  margin: 12px 0px;
}

.txt-right,
.txt-right input {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.font-bold input {
  font-weight: bold;
}

.no-border div[class*=" MuiInput"]:before {
  display: none;
}

.red {
  color: red;
}

.autoComplete + div {
  min-width: max-content !important;
  margin: 0px !important;
  /* since height is changed margin top is adjusted */
  /* margin-top: -15px !important; */
}

.autocomplete-fixed + div {
  min-width: 200px !important;
  margin: 0px !important;
  /* since height is changed margin top is adjusted */
  /* margin-top: -15px !important; */
}

.autoComplete + div + div,
.autocomplete-fixed + div + div {
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}

/* .autocomplete-marginDense {
    margin-top: 8px;.HHI, .MuiFormControl-marginDense
    margin-bottom: 4px;
} */
/* .houseHoldINCOME .MuiFormControl-marginDense {
    margin-top: 4px !important;
    margin-bottom: 6px !important;
} */
.MuiFormControl-root.MuiTextField-root.textBox--bordered {
  margin-top: 4px !important;
  margin-bottom: 6px !important;
}
.HHI,
.MuiFormControl-marginDense {
  margin-top: 7px !important;
  /* margin-bottom: 4px !important; */
  margin-bottom: 6px !important;
}

.HHI-autocomplete .MuiInput-input,
.HHI-date .MuiInput-root,
.HHI-textBox .MuiInput-root {
  border: 1px solid #ccc !important;
  padding: 4px 4px !important;
}

.HHI-date .MuiInput-root {
  padding: 0px 4px !important;
}

.HHI-textBox .MuiInput-root {
  padding: 0px 4px !important;
}

/* .UA-comment {
    height: 40px;
} */
.autocomplete-no-width + div {
  min-width: unset !important;
}

*[class*=" MuiFormHelperText-error"] {
  color: #f44336 !important;
}

.globalSearchList {
  font-size: 13px;
  padding-bottom: 0px;
  padding: 0px 16px;
  width: 455px;
  position: relative;
  top: 60px;
  overflow: hidden;
}

.globalSearchList .globalSearchTitle {
  font-size: 15px !important;
  color: gray !important;
}

.globalSearchList .resultFound {
  font-size: 13px !important;
  color: #36aebe !important;
  text-align: right;
}

.globalSearchList .resultTitle {
  font-size: 13px !important;
  font-weight: bold !important;
}

.globalSearchList .searchValue {
  padding-left: 0px !important;
}

.globalSearchList .searchListContent {
  overflow: auto;
}
.globalSearchList p {
  font-size: 0.875rem !important;
  color: rgb(29 26 26 / 75%);
  overflow: hidden;
}

.globalSearchList .fa {
  margin-right: 1px;
}

.globalSearchList .MuiInput-root {
  height: 38px !important;
}
.searchLoader {
  position: absolute;
  left: 40%;
  top: 50%;
  width: 200px !important;
  height: 80px !important;
  color: gray;
}

.searchLoader > svg {
  width: 80px !important;
  height: 80px !important;
}

.autoCompleteLoader {
  color: gray;
  display: flex;
  justify-content: center;
}

.autoCompleteLoader > svg {
  width: 40px !important;
  height: 40px !important;
}

.globalSearchList .autocompleteList {
  font-size: 11px;
}

.autocompleteList .autocompleteTag {
  margin-top: 16px;
  margin-bottom: 8px;
}
.homeOwnership {
  height: 500px;
}
.autocompleteList div[class*="MuiFormControl-marginDense"] {
  margin-top: 21px !important;
}

.autocompleteList div[class*="css-15k3avv"] {
  width: 450px !important;
}

.warning label {
  color: #ffc107 !important;
}

.title_issues.warning {
  background-color: #ffc107 !important;
}

.staticHeader tbody {
  display: block !important;
  height: 50px;
  overflow: auto;
}

.staticHeader thead,
.staticHeader tbody tr {
  display: table !important;
  width: 100%;
  table-layout: fixed;
  /* even columns width , fix width of table too*/
}

.staticHeader thead {
  width: calc(100% - 1em);
  /* scrollbar is average 1em/16px width, remove it from thead width */
}

.uploadfile {
  position: relative;
  width: 100%;
  height: 250px;
  border-width: 2px;
  border-radius: 5px;
  border-color: #666;
  border-style: dashed;
  background-color: #ecf7f9;
  text-align: center;
  line-height: 225px;
  font-size: 15px;
  outline: unset;
  letter-spacing: 0;
}

.uploadfile2 {
  height: 150px !important;
  line-height: 146px !important;
  letter-spacing: 0 !important;
}

.tabLoader {
  position: relative;
  top: -3px;
}

.helpUploadfile {
  position: relative;
  width: 100%;
  height: 50px;
  border-width: 2px;
  border-radius: 5px;
  border-color: #666;
  border-style: dashed;
  background-color: #ecf7f9;
  text-align: center;
  line-height: 50px;
  font-size: 15px;
  letter-spacing: 0;
}

.orOption {
  text-align: center;
}

.bolderText {
  font-weight: bold !important;
}

.uploadIncomefile {
  position: relative;
  width: "100%";
  height: 150px;
  border-width: 2px;
  border-radius: 5px;
  border-color: #666;
  border-style: dashed;
  background-color: #ecf7f9;
  text-align: center;
  line-height: 120px;
  font-size: 15px;
  letter-spacing: 0;
}

.react-player {
  margin-top: 40px;
}

.audio-style video {
  height: 40px !important;
  outline: unset !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* for auto complete dropdown visible */

.inner-scroll {
  height: 100%;
  width: 100%;
  overflow-x: auto;
}

/* .projectMerge{
    margin-top: -50px !important;
} */

.mergeProjectAutcomplete {
  padding: 12px 0 12px 90px;
  min-width: 200px !important;
  max-width: 460px !important;
}

.searchipdnd {
  /* margin-left: 40px; */
  /* border: 1px solid #ccc !important; */
  background: white;
  width: 100% !important;
  /* width: 300px !important; */
  text-align: center !important;
  padding-left: 10px !important;
}

.searchipMain {
  padding: 24px;
  display: flex;
  justify-content: center;
}

.dndContainer {
  max-width: 500px !important;
  padding-left: 50px;
}

.dndContMain {
  position: relative;
  background-color: #f1f1f1;
  margin-top: 24px;
  /* min-height: 500px; */
}

/* .dndContainerMain{
    padding-top: 10px;
    padding-left: 80px;
    background-color: #ccc

 } */
.dndMerge {
  padding-top: 30px;
  min-width: 550px;
  padding-left: 90px;
  overflow: auto !important;
}

.boxShadowWidthPadd-24 {
  padding: 24px !important;
  box-shadow: 0px 0px 8px #c1c1c1;
  overflow: hidden !important;
}

.mergeContainer {
  /* padding-top: 20px; */
  max-height: 500px !important;
  min-height: 450px !important;
  overflow: hidden !important;
}

.search-msg-merge-tool {
  width: 100%;
  padding: 24px;
  top: 70px;
  font-size: 25px;
  color: #ccc;
  font-weight: bold;
  text-align: center;
  position: absolute;
}

.tooltipDnd {
  border: none !important;
}

.tooltiphead {
  background-color: #f1f1f1;
  padding: 100px;
  min-width: 500px;
}

.tooltipbody {
  background: white;
  font-weight: bold;
  max-width: inherit;
  line-height: 20px;
  min-width: 500px;
}

.tooltipbody tr:nth-child(even) {
  background-color: #f1f1f1 !important;
}

.tooltipdesign {
  min-width: fit-content !important;
  background-color: #ccc !important;
  border-left-width: 1px solid #ccc !important;
  padding: 2px 2px !important;
  border-radius: 0px !important;
}

.tooltipdesign.left span[class^="ToolTip-arrowArrow"]:before {
  border-color: transparent #ccc transparent transparent !important;
}

.tooltipdesign.right span[class^="ToolTip-arrowArrow"]:before {
  border-color: transparent transparent transparent #ccc !important;
}

.commentMargin {
  margin-top: 12px;
}

#section-to-print {
  display: none;
}

tr.child-psr {
  display: none;
}

button.togglebutton-psr {
  display: inline-block;
  outline: 0;
  border: 0px;
  padding: 0px;
  width: 16px;
  height: 16px;
  background-color: #36aebe;
  color: #fff;
  font-weight: bold;
  line-height: 17px;
}

.reportStatus {
  position: relative;
}

.dashBoardSchema {
  font-size: 16px;
  position: inherit;
  margin-left: 25%;
}

.reportStatusLoader {
  position: absolute;
  left: 0;
  top: 0;
  width: 80px !important;
  height: 80px !important;
  margin: auto;
  bottom: 0;
  right: 0;
}

.reportStatusLoader svg {
  width: 80px;
  height: 80px;
}

@media print {
  body * {
    visibility: hidden;
  }

  #section-to-print {
    display: block;
    visibility: visible;
  }

  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }

  table thead tr th,
  table tbody tr td {
    padding: 2px !important;
    margin: 0px !important;
    height: auto !important;
  }
}

.expansion-edit-icon {
  display: flex;
  margin: 0 12px 0 24px;
  padding: 0 8px !important;
  align-self: center;
  border-radius: 8px;
  height: 26px;
  align-items: center;
}

.expansion-edit-text {
  font-size: 14px;
  font-weight: bold;
}

.expansion-edit-icon:hover {
  background-color: E0E0E0;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
    0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.expansion_error {
  background-color: #d32f2f !important;
  color: white;
}

.expansion_warning {
  background-color: #ffc107 !important;
  color: white;
}

.xmlEdittorbuttons {
  box-shadow: none;
  min-height: 36px;
  background-color: #e0e0e0;
  display: block;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  /* border: 1px solid red; */
  padding: 2px;
}

.xml-preview {
  height: 100%;
  border-top: 1px solid rgb(163, 160, 160);
  margin: 7px 0;
}
/*for  ho */
.ho-xml-editor {
  height: 109%;
  border: 1px solid black !important;
  margin: 12px 0 !important;
}

.xml-codemirror .CodeMirror {
  height: 103% !important;
}

.fullXMLEDT .xml-codemirror .CodeMirror {
  height: 108% !important;
}

.xml-preview .error-header {
  font-size: 16px;
  font-weight: bold;
  padding: 4px 8px;
  border-bottom: 1px solid;
  text-transform: uppercase;
}

/*for  ho */
.ho-xml-editor .error-container {
  padding: 8px !important;
}

.error-container .error-content {
  height: 47.6vh;
  overflow: auto;
  background: #f4f4f4;
}

.success-container {
  overflow: auto;
  /* background: #f4f4f4; */
  width: 100%;
  height: 47.6vh;
}

.success-table-container {
  width: 90%;
  margin:10px auto;
  background-color: white;
  border-radius: 4px;
  border: 1px solid #DADADA;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, .08);
}

tr.success-table-row {
  /* display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #dadada; */
  border-bottom: 1px solid #dadada; 
}

.success-table-cell {
   padding: 2%;
   text-align: left;
}

.heading {
  font-weight: 800;
}

.ho-xml-editor .error-container .error-content {
  border: 1px solid black !important;
  max-height: 250px !important;
  overflow: auto !important;
}

.fullErrXml .error-container .error-content {
  height: 61vh;
}

.xml-editor-error .error-content .error-list {
  padding: 4px;
  border: 1px solid white;
  width: 97%;
  margin: 10px;
  background: #ffffff;
  border-style: solid;
  border-left-width: thick;
  border-left-color: crimson;
}

.ho-xml-editor .error-content .error-list {
  padding: 4px !important;
}

.error-content .error-list:hover {
  background-color: #a5a5a547;
  cursor: pointer;
}

.xml-preview .xml-editor {
  padding: 8px 8px 0 8px;
  margin-top: 1px;
}

.xml-editor .ReactCodeMirror {
  border: 1px solid black;
  padding-bottom: 1px;
}
.ReactCodeMirror {
  height: 82%;
}

.table-err-msg {
  position: relative;
}

.table1-err-msg {
  padding: 0 0px 0px 0;
}

.table-err-msg .exp-name {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-self: center;
  cursor: pointer;
  font-size: 10px;
  /* color: #36aebe;
    border-bottom: 1px solid; */
  height: 16px;
}
.MuiSnackbarContent-message {
  padding-right: 20px;
}

.table1-units {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-height: 40px;
  max-width: 85%;
}

.table1-units .unit {
  padding-top: 2px;
  margin: 0 4px;
  /* color: #36aebe; */
  /* border-bottom: 1px solid; */
  cursor: pointer;
  height: 20px;
}

.Table1-root-239 {
  background: white;
}

.opened-card {
  height: 100%;
  /* overflow: auto; */
}

.Table1-root-109 {
  padding: 0px !important;
}

.closed-card {
  height: 0;
  overflow: hidden;
}

.paddingLR16 {
  padding: 0 16px;
}

.table-error-card {
  cursor: pointer;
}

.MuiTypography-body1 {
  line-height: 1.3 !important;
}

.beginning-bal {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  white-space: pre-wrap;
}

/* .MuiInput-input{
    font-size: 1rem !important;
} */
#alert-dialog-title h2 {
  font-size: 1.3125rem;
  line-height: 1.16667em;
}
/* .disabledGrey .Mui-disabled {
    color: rgba(0, 0, 0, 0.38);
} */

.Mui-disabled::before {
  border-bottom: 1px dotted #ddd !important;
}

/*
    Autocomplete alignment fix
*/
.MuiFormControl-fullWidth.MuiFormControl-root.MuiTextField-root.autoComplete,
.autocomplete-fixed {
  margin-top: 4px;
  margin-bottom: 4px;
  /* height: 46px; */
}

/* .MuiFormControl-marginDense{
    height: 46px;
} */

.autocompleteList.no-margin .autoComplete,
.autocompleteList.no-margin .autocomplete-no-width {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  /* height: 25px !important; */
}

.autocompleteList.race-sel .MuiListItem-root {
  padding-left: 8px !important;
}

.textbox-no-margin {
  margin: unset !important;
  /* height: 28px !important; */
}

.menu-icon {
  font-size: 16px;
}

.userIcon {
  height: 24px;
  width: 45px;
}
.table1Comments {
  padding: 0px !important;
}

/*
max width for text area
*/
.w-max-c {
  width: 500px !important;
}

.adjust-height textarea {
  padding-top: 2px !important;
  font-size: 0.875rem !important;
}

.UAtable .adjust-height {
  margin-top: -2px !important;
}

/* .MuiGrid-item{
    align-self: center;
} */
.delete_icon {
  color: #36aebe !important;
}

a {
  letter-spacing: 0;
}

.autocomplete-list span {
  white-space: normal;
}

.buttonAlignEnd {
  text-align: end;
}

/* To align the icon to center */
i {
  height: 14px;
  width: 20px;
  padding-left: 1px;
  padding-top: 1px;
}

.occupants_Table {
  line-height: 1.3 !important;
}
.uascroll {
  overflow-y: scroll;
}

.uacustom .p-b-0 {
  overflow: hidden !important;
}
.uacustom .MuiTableContainer-root {
  /* overflow: scroll !important; */
  height: 658px;
}
/* scroll for UA */
.UAtable .MuiTableContainer-root {
  height: 100%;
  overflow-y: auto !important;
  overflow-x: auto !important;
}

/* .uacustom .MuiGrid-root{
    padding-right: 0px;
} */
.UAV-btn-container {
  padding-right: 15px;
}
.DIA-pop-container .MuiDialogTitle-root {
  padding: 24px 24px 0px 18px;
}

.occupant-container {
  display: contents !important;
}

.occupant-container .p-b-0 {
  /* height: 606px !important; */
  overflow: hidden !important;
}

.dropdowmMultiSelect .MuiInput-root {
  /* margin-bottom: 0px!important; */
  min-height: 38px;
  justify-content: center;
}

/* .textBoxExplore {
    height: 45px;
} */
.Table1-root-239 {
  background: white !important;
}
.autocompleteExplore .MuiInput-root {
  min-height: 38px;
  max-width: 140px;
  justify-content: center;
  border: 1px solid #ccc !important;
  width: 100% !important;
  margin-bottom: 2px;
  /* margin-top: 7px; */
}
.datepicker .MuiInputBase-input.Mui-disabled {
  opacity: 1 !important;
}
/* .datepicker label{
    text-align: left !important;
    padding-bottom: 50px !important;
} */
.MuiInputBase-input.Mui-disabled {
  cursor: not-allowed !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.75) !important;
}

.MuiFormLabel-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.6);
}
.MuiInput-root.MuiInput-underline::before {
  border-bottom: 1px solid #ddd !important;
}

.toolbarRight {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.table4-bodered-text {
  margin: 4px 0;
}

.textBox--bordered input {
  min-height: 26px;
}

/* 
reduce input height 
*/
label + .MuiInput-formControl {
  margin-top: 12px !important;
}

/* 
Reduce snackbar height 
*/
.MuiSnackbarContent-root .MuiButton-root {
  min-height: unset !important;
}

/* 
Global search alignment 
*/
.globalSearch .MuiInput-root,
.autocompleteTag .MuiInput-root {
  height: 38px;
}

.autocompleteTag .MuiInput-root {
  height: 30px;
}

.MuiSwitch-root .MuiIconButton-root {
  margin-left: 1px !important;
  height: 38px;
  width: 38px;
}

/* .MuiSwitch-sizeSmall .MuiSwitch-thumb{
    margin-bottom: 4px;
} */
.MuiSwitch-sizeSmall .MuiSwitch-switchBase {
  height: 22px;
  width: 22px;
}

.selectYear-icon {
  width: 25px !important;
  height: 25px !important;
}

.selectYear {
  margin-top: 8px !important;
}

.table-row {
  display: flex !important;
}

.list-table {
  display: flex;
  /* max-height: 310px; */
  position: relative;
}

.list-table .sticky-table {
  box-shadow: 0px 0px 8px 0px grey;
  z-index: 3;
  /* height: max-content; */
  /* position: absolute;
    width: 72%;
    right: 0;
    left: 0; */
}

.list-table .scroll-table {
  /* min-width: 100%; */
  padding-left: 4px;
  /* margin-left: 72%; */
  overflow-x: auto;
  /* height: max-content; */
}

.scroll-table-full-width {
  min-width: 100%;
  /* padding-left: 4px; */
  overflow-x: auto;
  border: 1px solid #e0e0e0;
}
.unitsBulkUpdate {
  height: 100%;
  width: 100%;
}

.unitsBulkUpdate .MuiTableHead-root {
  position: sticky;
  top: 0;
  z-index: 1;
}
/* .explorePreview .scroll-table {
    min-width: 100%;
}

.explorePreview{
    overflow: hidden !important;
}

.explorePreview>div>div{
     overflow: auto !important; 
    height: 87% !important;
}

.explorePreview .scroll-table-full-width{
    height: 300px !important;
} */

.explorePreview thead {
  position: sticky !important;
  top: 0 !important;
  overflow: hidden !important;
  background-color: white !important;
  z-index: 2 !important;
}
.explorePreview {
  overflow: hidden !important;
}
.complianceSettings {
  height: 100%;
  width: 100%;
}

.complianceSettings .MuiTableHead-root {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}
/* .list-table thead{
    position: absolute;
} */
.scroll-table .MuiIconButton-root {
  height: 24px;
  width: 24px;
}

.width400 {
  width: 900px !important;
}

.marginTopImport {
  margin-top: 15px;
}

.autocompleteListFT {
  width: 100px !important;
}

.apartmentIcon {
  margin-bottom: 1px;
  margin-right: 1px;
}

.backButton {
  margin-top: 6px !important;
  margin-right: 15px !important;
}

.help-pdfIcon i,
.help-playIcon i {
  font-size: 24px;
}

.help-pdfIcon {
  color: red;
}

.help-playIcon {
  color: #36aebe;
}

.help-pdfIcon,
.help-playIcon {
  height: 24px;
  width: 24px;
}

.react-player {
  margin-top: 12px;
  outline: unset;
}

.closeHelp {
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.filterDelete {
  position: absolute;
  top: 14px;
  right: 12px;
}

.flexIcon {
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 10px !important;
}

.exploreMenu .MuiFormControlLabel-label {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.exploreForm > div {
  margin: unset;
}
.exploreForm .MuiFormControl-marginDense {
  width: 100%;
}

.accordionSearch {
  width: 100%;
}

.sidemenu-icon {
  display: flex !important;
  justify-content: center;
  width: 18px;
  height: 18px;
  font-size: 18px !important;
}

.sort-width {
  width: 30px;
}

.drag-icon {
  /* transform: rotate(90deg); */
  color: #909090;
  font-size: 14px !important;
}

.flexForTotalValue {
  color: #909090;
  font-size: 15px !important;
  display: flex;
}

.flexForTotalValue div > b {
  color: black;
}

.flexForIcon {
  display: flex;
  justify-content: space-between;
  margin: 20px 15px 20px 15px;
}

.paddingTotalValue {
  padding-right: 50px;
}

.complianceChart {
  margin-top: 6%;
}

.iconNeedsAction {
  display: flex;
}

.borderNeedsAction {
  box-shadow: 0px 0px 2px grey;
}

.borderRightAccordion {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.needsAction {
  margin-top: 10px !important;
}

.iconNeedsAction .MuiButtonBase-root {
  border-radius: 0px !important;
  border-color: #dddddd;
}

.iconBgColor {
  background-color: #fbaa92 !important;
}

.iconBgColorInactive {
  background-color: #f4f4f4 !important;
}

.reportYear {
  font-size: 16px;
  cursor: pointer;
  vertical-align: middle;
  /* padding: 0 8px; */
}

.reportYear i {
  font-weight: 600;
}

.needsActionPadding {
  padding: 0 15px;
}

/* Move these changes inside component */

.preview-layout {
  height: 100%;
  position: relative;
  /* padding-top: 36px; */
}

.preview-container {
  background-color: #f4f4f4;
  padding: 24px;
  box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  min-height: calc(100% - 60px);
  display: flex;
}

.preview-content {
  width: 100%;
  background: #ffffff;
  padding: 24px;
  word-wrap: break-word;
}

.preview-title {
  color: #838383;
  font-weight: 700;
  padding: 8px 0px;
}

.mailTemplateEditor {
  min-height: 85px !important;
  max-height: 85px;
  overflow-x: auto !important;
  padding: 0px 0 0px 4px;
}

.sendnotifycontainer {
  min-height: 70px !important;
  max-height: 70px !important;
}

.sendMailNotify .MuiDialogActions-root {
  padding: 0px 24px 24px 24px;
}
/* .loanprojalert{
    width: 600px !important;
    justify-content: center;
    align-content: center;
    margin:auto;
} */
.sendMailBody {
  margin: 8px 0px 0px 0px;
}

/* .mailTemplateEditor .public-DraftStyleDefault-block {
    margin: 2px 0;
} */

.mail-preview-image {
  background: #36aebe;
  padding: 8px;
}

.preview-footer-divider {
  border-top: 1px solid #ebebeb;
}

.email-preview-footer {
  padding-top: 8px;
  font-size: 12px;
  color: #8d8d8d;
}

.custom-label {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0;
  padding-top: 8px;
  padding-bottom: 16px;
}

.cutom-editor-gap {
  padding-top: 8px;
  border-bottom: 1px solid #ebebeb;
}

.DraftEditor-root {
  z-index: 0;
}

.accordin-no-records {
  text-align: center;
  padding: 12px;
  color: #808080;
}

.maintenance-block {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #36aebe;
  padding: 12px;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.m_table .scroll-table-full-width {
  border: none;
}

.m_table table {
  padding: 8px;
}

.dialogBoxTitle {
  /* display: flex; */
  justify-content: space-between;
}

.org-alert {
  height: 150px;
  overflow-y: scroll;
}

.dialogBoxTitle .space-title {
  display: flex;
  justify-content: space-between;
}

.closeAlert {
  display: flex;
  justify-content: flex-end;
}

.editorClassName {
  padding-left: 12px;
}

.last-child-border tbody tr td:last-child {
  border-right: 1px solid #ddd !important;
}

.save-align {
  padding: 16px 16px;
}

.dialog-align {
  padding: 12px 24px !important;
}

.global-box .MuiPaper-root {
  box-shadow: 0px 0px 10px #ddd;
}

.confirm-box .MuiDialogTitle-root {
  flex: 0 0 auto;
  padding: 16px 24px 0px 20px;
}

.drawerSearch {
  margin-top: 6px;
}

.right-search {
  text-align: right;
  margin-top: -16px;
}

.left-search {
  margin-bottom: -25px;
}

.funder_user {
  /* min-height: 300px; */
  min-width: 500px;
}

.errorClassName {
  border-color: #f44336 !important;
}

.errorClassName .DraftEditor-root .public-DraftEditorPlaceholder-root {
  color: #f44336 !important;
}

.error-label {
  color: #e32;
}

.groupedCheckBox {
  position: relative !important;
  margin-left: 20px;
  margin-right: 20px;
  background-color: #eff9fa !important;
  color: #36aebe !important;
  margin-top: 20px;
}
.GrpMenuItem {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.GrpMenuItemcustom {
  margin-left: 70px !important;
  margin-right: 20px !important;
}

.GrpMenuItem:last-child {
  margin-bottom: 20px;
}
.errorMessage {
  color: #f44336;
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 4px;
  font-size: 0.75rem;
}
.globalSearchSelectButton {
  color: #e32;
}
.autocompleteTags {
  margin-top: 100px;
}
.buttonsearchsss {
  height: 10px !important;
}

.required-checkbox,
.required-checkbox span {
  color: rgb(244, 67, 54);
}

.autocomplete-bordered-material-table {
  border: 1px solid #ccc !important;
}

.textbox-bordered-material-table input {
  border: 1px solid #ccc !important;
  width: 100% !important;
  /* min-height: 52px; */
}

.textbox-bordered-material-table input {
  padding: 5px 3px !important;
}
.textbox-bordered-material-table .MuiInputBase-multiline,
.MuiInputBase-multiline {
  padding: 5px !important;
}
.textbox-bordered-material-table *::before {
  display: none !important;
}
.zIndex3 {
  z-index: 3;
}
.unset-margin-tb {
  margin-top: unset !important;
  margin-bottom: unset !important;
}
.MuiSelect-selectMenu {
  padding: 5px 24px 5px 3px !important;
}
.th-light-grey th {
  background-color: #eeeeee;
}
.bg-light-grey {
  background-color: #eeeeee;
}
/* .certificationDelete_icon{
    margin-bottom: 5px;
}
.certificationDelete_icon{
    font-size: 10px;
    color: #36aebe !important;
    height: 10px !important
} */
.maxAllowedIncome > label {
  margin-top: -8px;
  text-align: left;
  width: 100%;
}
.maxAllowedIncome::after > label {
  width: 100% !important;
}

.annualGross {
  width: 100% !important;
}

.annualGross > label {
  text-align: left;
  width: 125% !important;
  /* margin-top: -12px !important; */
}

@media only screen and (min-width: 1401px) {
  .annualGrosss label p {
    /* bottom:22px !important; */
    position: relative;
    display: inline;
  }

  .annualGrosss label::after {
    position: relative;
    /* top: -13px !important; */
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1400px) {
  .dashBoardSchema {
    font-size: 14px;
    margin-left: 22%;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1480px) {
  .dashBoardSchema {
    margin-left: 21%;
  }
}

@media only screen and (max-width: 1400px) {
  .annualGrosss label p {
    bottom: 13px !important;
    position: relative;
    display: inline;
    line-height: 10px;
  }

  .annualGrosss label::after {
    position: relative;
    top: -15px !important;
  }

  .dashBoardSchema {
    margin-left: 21%;
  }
}

.focusAlign > label {
  text-align: left;
  margin-top: -7px !important;
}

.maxAllowedIncome {
  width: 100% !important;
}
.addresscert > td {
  border-bottom: none !important;
  width: 100vw !important;
  /* width: 200px; */
}
.addresscert input {
  margin-top: -5px;
}
.addresscert label {
  margin-top: -2px;
}
.deleteCert > td {
  border-bottom: none !important;
}
.deleteCert > td > button {
  margin-top: 19px !important;
  /* margin-left: 80px !important; */
}
/* .tooltiptip>div>input{
    width:40px ;
} */
/* .loanPagination>a{
    background-color:#2F67CC !important;

} */
/* .loanPagination> li.active > a {
    background-color: #2F67CC;
    color: #fff;
} */
.loanPagination {
  margin: 0;
  padding: 0;
  list-style: none;
  float: right;
  margin-top: 6px;
  /* color:#909090; */
}

.loanPagination > li {
  float: left;
}
.globalSearchCategory {
  display: flex;
}
.loanPagination > li > a {
  margin: 0 2px;
  border-radius: 50%;
  line-height: 30px;
  width: 30px;
  height: 30px;
  display: block;
  text-align: center;
  cursor: pointer;
}

.loanPagination > li.active > a {
  background-color: #2f67cc;
  color: #fff;
}

.loanPagination > li > a:hover {
  background-color: #2f67cc;
  color: #fff;
}

.loanPagination > li.isDisablePagination > a:hover {
  background-color: #fff;
  /* color: #fff; */
}

.pagination-block-Prev-Next .loanPagination {
  margin-top: 0 !important;
}
.loanTableCellColor {
  color: #2f67cc !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #36aebe !important;
}
.outlineMulti.Mui-focused fieldset {
  border-color: #36aebe !important;
}
input[type="text"] {
  font-size: 0.875rem !important;
  color: rgba(0, 0, 0, 0.75);
}

/* input[type="number"] {
    color: rgba(0, 0, 0, 0.75);
} */
.autocompleteFix p {
  font-size: 0.875rem !important;
  color: rgba(0, 0, 0, 0.75);
}

.autocompleteFix .MuiFormHelperText-root.Mui-error {
  font-size: 00.75rem !important;
}
input[type="number"] {
  font-size: 0.875rem !important;
}
.autoCompleteTop .autocompleteFix {
  margin-top: -3px;
  width: 100%;
}

.autoCompleteTop10 {
  margin-top: 9px !important;
  width: 100%;
  overflow-y: visible !important;
}
.CustomOutlineText > div {
  border-radius: 0px !important;
}

.CustomOutlineText {
  width: 208px !important;
}

.organization_type label + .MuiInput-formControl {
  margin-top: 18px !important;
}
.organization_type .MuiSelect-root {
  margin-top: -1px;
}
.organization_type .MuiSvgIcon-root {
  margin-right: 10px;
}
.disabledDelete {
  font-size: 22px;
  margin-left: -92px;
  margin-bottom: 31px;
}
.loanAddIcons {
  background-color: #2f67cc !important;
  /* background-color: #36aebe; */
  padding-left: 6px;
  padding-top: 6px;
  border-radius: 14px;
  height: 25px;
  width: 25px;
  margin-right: 6px;
}
.multiTextArea h2.MuiTypography-h6 {
  display: flex;
  justify-content: space-between;
}
.marginMultipletextArea {
  margin-bottom: 15px;
}
.loanAddDisabled {
  background-color: #f6f8fa !important;
  /* background-color: #36aebe; */
  padding-left: 6px;
  padding-top: 6px;
  border-radius: 14px;
  height: 25px;
  width: 25px;
  margin-right: 6px;
}
.homeDisableAddRow {
  margin-left: -58px;
  margin-bottom: 36px;
}
.homePurchasealign {
  display: -webkit-inline-box !important;
  margin-top: 23px !important;
  margin-left: 33px !important;
}

.disableAddRow {
  pointer-events: auto !important;
  cursor: not-allowed !important;
  color: #797a7c !important;
}

.colorAddrow {
  color: #2f67cc !important;
}

.reportSubmit {
  color: #36aebe !important;
  font-size: 14px !important;
  margin-left: 55px !important;
}

label {
  font-size: 0.875rem !important;
}

/* date picker */
/* .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  } */

.tooltiptext {
  /* visibility: hidden; */
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 5px 5px;
  font-size: 00.875rem;
  margin-top: -50px;
  bottom: 110% !important;
  /* Position the tooltip */
  position: absolute !important;
  z-index: 1500;

  /* overflow: auto; */
}
.nonsweat {
  margin-left: -230px;
  top: -5%;
  max-height: 300px;
  /* overflow: hidden; */
  /* text-overflow: initial;
    white-space: nowrap; */
}
.tooltiptextNone {
  display: none;
}
.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.containers {
  position: relative;
}
.containers:hover .tooltiptextHover {
  display: block;
  width: auto;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 5px;
  font-size: 00.875rem;

  /* Position the tooltip */
  position: absolute;
  bottom: 109% !important;
  z-index: 9;
  /* overflow: auto; */
}
.containers:hover .tooltiptextHover::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.containers:hover .certtooltiptextHover {
  display: block;
  width: fit-content !important;
  background-color: rgb(102, 101, 101);
  color: white;
  text-align: left;
  box-shadow: 0 0 10px #ccc;
  /* border-radius: 6px; */
  padding: 8px;
  font-size: 00.875rem;

  /* Position the tooltip */
  position: absolute;
  bottom: 120%;
  z-index: 9;
  /* overflow: auto; */
}

.certtooltiptextHover {
  display: none;
}

.tooltiptextHover {
  display: none;
}
.css-1pahdxg-control {
  box-shadow: 0 0 0 1px #36aebe !important;
}

.containers:hover .certtooltiptextHover::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: rgb(102, 101, 101) transparent transparent transparent;
}

.loanContent .parentCard {
  overflow-x: hidden !important;
}

.groupCheklabel label {
  color: rgba(0, 0, 0, 0.38);
}

.websiteLink:visited {
  color: #0000ee;
}

.ethincityCheckbox input {
  z-index: 0 !important;
}

.submitGrid {
  margin-left: -500px !important ;
}

.unsubmitGrid {
  margin-right: 20px;
}

.reportNameWidth {
  width: 100%;
  margin-top: 19px !important;
}

.reportNameWidth div {
  margin-top: 9px !important;
}

.reportNameWidth > label {
  margin-top: -8px;
}
.closeIcon svg {
  margin-left: 900px !important;
}
.commentBox {
  margin-bottom: 5px;
}
.commentsFinancial {
  font-weight: 25px;
  font-size: large;
}
.loanDeleteIcon {
  font-size: 14px !important;
  color: #2f67cc !important;
}

.homePurchaseCity td {
  border-bottom: 1px solid #ffffff !important;
}

.homePurchaseState td {
  border-bottom: 1px solid #ffffff !important;
}

.occupantsView {
  height: 100%;
  width: 100%;
}
/* inner scroll for occupants */
.occupantsView .parentCard {
  overflow: hidden !important;
  height: 100%;
  width: 100%;
}
.occupantsView .occupantsTab {
  overflow: auto !important;
  height: 100%;
  padding-bottom: 60px;
}
.generalUI {
  overflow: auto;
  height: 474px;
}
.UAtable .MuiTableContainer-root {
  height: 100%;
  overflow-y: auto !important;
  overflow-x: auto !important;
}
.usersView {
  height: 100%;
  width: 100%;
}
/* inner scroll for users */
.usersView .parentCard {
  overflow: hidden !important;
}
/* .usersView .userEdit {
    overflow: auto !important;
    height: 100%;
    padding-bottom: 40px;
   } */
.certificationAddRow {
  margin-top: 13px !important;
}
/* .menuUlsss > ul{
       width: 100% !important;
       height: 300px !important;
   } */

.text .count {
  font-size: 24px !important;
}
/* .HODashboardSummary{
       height: 184px !important;
   }  */
.loanRate > div {
  height: 397px;
}
.UAtable thead {
  position: sticky !important;
  top: 0 !important;
  overflow: hidden !important;
  background-color: white !important;
  z-index: 2 !important;
}
.helpContainer thead {
  position: sticky !important;
  top: 0 !important;
  overflow: hidden !important;
  background-color: white !important;
  z-index: 2 !important;
}
.moduleAutocomplete i {
  padding-left: 28px !important;
}

.conditionDropdown > div {
  margin-bottom: 1px !important;
}

.conditionDropdown .Mui-disabled .MuiInput-underline:before {
  border-bottom: 1px solid #ddd !important;
}

/* .conditionDropdown .MuiInput-underline:before {
    border-bottom: 1px solid #948f8f !important;
} */

/* .valueDropdown .MuiInput-underline:before{
    border-bottom: 1px solid #948f8f !important;
} */

/* .explore{
    height: 367px !important;
} */

/* .loanFunder .childCard{
    height: 782 !important;
} */

.modifiedNumberField {
  width: 208px !important;
}
.expDateFunder {
  width: 100% !important;
}
.firstrptYearFunder {
  margin-top: -6px !important;
}
.modifiedNumberField > div {
  border-radius: 0px;
}
.modifiedNumberField .Mui-focused fieldset {
  border-color: #36aebe !important;
}

/* .loanProjectHeight .parentCard:not(:second-child){
    height: 516px !important;
} */

.dashreport:nth-child(4) {
  height: 100% !important;
}

.downloadReportButton span {
  white-space: nowrap;
}
.downloadReportButton {
  margin-left: -21px !important;
}

/* .ReportDownloadBox {
    padding-left: 4px !important;
} */

/* .loanfund .childCard{
    height: 470px !important;
} */

.headerUserLoan {
  background-color: #fff !important;
}

.headerUserLoan.reportTabSelected {
  background-color: #ecf7f9 !important;
}

.dashboardHOreport {
  padding-bottom: 30px !important;
}
/* 
.customToolTip {
    position: relative;
    display: inline-block;
}
  
.customToolTip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: gray;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: 00.875rem;
    margin-top: -50px;
  
    Position the tooltip
     position: absolute;
    z-index: 1500; 
} */

/* .customToolTip .tooltiptext.show {
    visibility: visible;
} */
.topTextBox {
  /* visibility: hidden; */
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 0px 5px !important;
  font-size: 00.875rem;
  /* margin-top: -50px; */
  top: 195% !important;
  /* Position the tooltip */
  position: absolute;
  z-index: 1500;

  /* overflow: auto; */
}

.topTextBox::after {
  content: "";
  position: absolute;
  top: -11%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}
.topTextBoxHover {
  display: none;
}
.containers:hover .topTextBoxHover {
  display: block;
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: left;
  border-radius: 0px;
  padding: 0px 5px;
  padding-top: 2px !important;
  font-size: 00.875rem;

  /* Position the tooltip */
  position: absolute;
  top: 100% !important;
  z-index: 9;
  /* overflow: auto; */
}
.containers:hover .topTextBoxHover::after {
  content: "";
  position: absolute;
  top: -11% !important;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

/* outlined top tooltip */

.topOutlineTextBox {
  width: 300% !important;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px 5px;
  font-size: 00.875rem;
  margin-top: 25% !important;
  /* bottom: -135% !important; */
  /* Position the tooltip */
  position: absolute;
  z-index: 1500;
}

.topOutlineTextBox::after {
  content: "";
  /* margin-top: -6% !important; */
  position: absolute;
  top: -25%;
  left: 5%;
  /*margin-left: 2px; */
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

.topOutlineTextBoxHover {
  display: none;
}
.containers:hover .topOutlineTextBoxHover {
  display: block;
  width: 300% !important;
  background-color: gray;
  color: #fff;
  text-align: left;
  border-radius: 0px;
  padding: 8px;
  font-size: 00.875rem;

  position: absolute;
  margin-top: 25% !important;
  z-index: 9;
}
.containers:hover .topOutlineTextBoxHover::after {
  content: "";
  position: absolute;
  top: -23%;
  left: 5%;
  /* margin-left: 2px; */
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

.financialTopTextBox {
  display: block;
  width: 100%;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px !important;
  font-size: 00.875rem;

  position: absolute !important;
  bottom: -50% !important;
  margin-left: 220px !important;
  z-index: 9;
}

.financialTopTextBox::after {
  content: "";
  position: absolute;
  top: 45%;
  left: -4%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.financialTopTextBoxHover {
  display: none;
}
.containers:hover .financialTopTextBoxHover {
  display: block;
  width: 100%;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px;
  font-size: 00.875rem;

  position: absolute !important;
  bottom: -50% !important;
  margin-left: 220px !important;
  z-index: 9;
}
.containers:hover .financialTopTextBoxHover::after {
  content: "";
  position: absolute;
  bottom: 30% !important;
  left: -5% !important;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.textAreaTooltip {
  width: 500px;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 5px 5px;
  font-size: 00.875rem;
  margin-top: -50px;
  bottom: 117% !important;
  /* Position the tooltip */
  position: absolute !important;
  z-index: 1500;

  /* overflow: auto; */
}

.textAreaTooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.textAreaTooltipHover {
  display: none;
}
.containers:hover .textAreaTooltipHover {
  display: block;
  width: 60%;
  background-color: gray;
  color: #fff;
  text-align: left;
  border-radius: 0px;
  padding: 8px;
  font-size: 00.875rem;

  position: absolute;
  bottom: 120% !important;
  z-index: 9;
}
.containers:hover .textAreaTooltipHover::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

@media only screen and (min-width: 1500px) {
  .textAreaTooltip {
    width: 500px;
    background-color: gray;
    color: #fff;
    text-align: left;
    /* border-radius: 6px; */
    padding: 5px 5px;
    font-size: 00.875rem;
    margin-top: -50px;
    bottom: 117% !important;
    /* Position the tooltip */
    position: absolute !important;
    z-index: 1500;

    /* overflow: auto; */
  }
}

.fintextAreaTooltip {
  width: 500px;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 5px 5px;
  font-size: 00.875rem;
  margin-top: -50px;
  bottom: 125% !important;
  /* Position the tooltip */
  position: absolute !important;
  z-index: 1500;

  /* overflow: auto; */
}

.fintextAreaTooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.fintextAreaTooltipHover {
  display: none;
}
.containers:hover .fintextAreaTooltipHover {
  display: block;
  width: 60%;
  background-color: gray;
  color: #fff;
  text-align: left;
  border-radius: 0px;
  padding: 8px;
  font-size: 00.875rem;

  position: absolute;
  bottom: 125% !important;
  z-index: 9;
}
.containers:hover .fintextAreaTooltipHover::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.topTextBox {
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 0px 5px;
  font-size: 00.875rem;
  /* margin-top: -50px; */
  /* bottom: -150% !important; */
  top: 110% !important;
  position: absolute;
  z-index: 1500;
}

.topTextBox::after {
  content: "";
  position: absolute;
  top: -17%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

.topTextBoxHover {
  display: none;
}
.containers:hover .topTextBoxHover {
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 0px 5px;
  font-size: 00.875rem;
  /* margin-top: -50px; */
  /* bottom: -150% !important; */
  top: 110% !important;
  position: absolute;
  z-index: 1500;
}
.containers:hover .topTextBoxHover::after {
  content: "";
  position: absolute;
  top: -13%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

@media only screen and (max-width: 1500px) {
  .topTextBox::after {
    content: "";
    position: absolute;
    top: -11%;
    left: 5%;
    margin-left: 2px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent gray transparent;
  }
}

@media only screen and (min-width: 1500px) {
  .containers:hover .topTextBoxHover::after {
    content: "";
    position: absolute;
    top: -15% !important;
    left: 5%;
    margin-left: 2px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent gray transparent;
  }
}

.topTextBoxLeftAlign {
  /* visibility: hidden; */
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 5px 5px;
  font-size: 00.875rem;
  margin-top: -50px;
  left: 0%;
  bottom: 115% !important;
  /* Position the tooltip */
  position: absolute !important;
  z-index: 1500;

  /* overflow: auto; */
}

.topTextBoxLeftAlign::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.containers:hover .topTextBoxLeftAlignHover {
  display: block;
  width: auto;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 8px;
  font-size: 00.875rem;

  /* Position the tooltip */
  position: absolute;
  bottom: 115% !important;
  z-index: 9;
  /* overflow: auto; */
}
.topTextBoxLeftAlignHover {
  display: none;
}
.containers:hover .topTextBoxLeftAlignHover::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.certificationtopTextBox {
  /* visibility: hidden; */
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: center;
  /* border-radius: 6px; */
  padding: 5px 0;
  font-size: 00.875rem;
  margin-top: -50px;
  /* left:0%; */
  bottom: 110% !important;
  /* Position the tooltip */
  position: absolute !important;
  z-index: 1500;

  /* overflow: auto; */
}

.certificationtopTextBox::after {
  content: "";
  position: absolute;
  top: 100%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

.certificationtopTextBoxHover {
  display: none;
}
.containers:hover .certificationtopTextBoxHover {
  display: block;
  width: auto;
  background-color: gray;
  color: #fff;
  text-align: left;
  left: 0%;
  /* border-radius: 6px; */
  padding: 8px;
  font-size: 00.875rem;

  /* Position the tooltip */
  position: absolute;
  top: 110% !important;
  z-index: 9;
  /* overflow: auto; */
}
.containers:hover .certificationtopTextBoxHover::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

.autocomplteTextBox {
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 5px 5px;
  font-size: 00.875rem;
  margin-top: -50px;
  bottom: 120% !important;
  /* Position the tooltip */
  position: absolute !important;
  z-index: 1500;

  /* overflow: auto; */
}

.autocomplteTextBox::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.containers:hover .autocomplteTextBoxHover {
  display: block;
  width: auto;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 5px;
  font-size: 00.875rem;

  /* Position the tooltip */
  position: absolute;
  bottom: 117% !important;
  z-index: 9;
  /* overflow: auto; */
}

.containers:hover .autocomplteTextBoxHover::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.autocomplteTextBoxHover {
  display: none;
}

.finanfour {
  word-wrap: break-word;
  width: 100% !important;
}

.rightOutlined {
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 5px 5px;
  font-size: 00.875rem;
  margin-top: -50px;
  bottom: -80% !important;
  left: 78%;
  /* Position the tooltip */
  position: absolute !important;
  z-index: 1500;

  /* overflow: auto; */
}

.rightOutlined::after {
  content: "";
  position: absolute;
  top: 45%;
  left: -6%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.containers:hover .rightOutlinedHover {
  display: block;
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 5px;
  font-size: 00.875rem;

  /* Position the tooltip */
  position: absolute !important;
  bottom: -80% !important;
  left: 78%;
  z-index: 9;
  /* overflow: auto; */
}

.containers:hover .rightOutlinedHover::after {
  content: "";
  position: absolute;
  top: 45%;
  left: -6%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.rightOutlinedHover {
  display: none;
}

.righSecondtOutlined {
  display: block;
  width: 100%;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px;
  font-size: 00.875rem;

  position: absolute !important;
  bottom: -5% !important;
  margin-left: 220px !important;
  z-index: 9;
}

.righSecondtOutlined::after {
  content: "";
  position: absolute;
  bottom: 30% !important;
  left: -3.5% !important;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.containers:hover .righSecondtOutlinedHover {
  display: block;
  width: 100%;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px;
  font-size: 00.875rem;

  position: absolute !important;
  bottom: -5% !important;
  margin-left: 220px !important;
  z-index: 9;
}

.containers:hover .righSecondtOutlinedHover::after {
  content: "";
  position: absolute;
  bottom: 30% !important;
  left: -5% !important;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.righSecondtOutlinedHover {
  display: none;
}

.righThirdtOutlined {
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 5px 5px;
  font-size: 00.875rem;
  margin-top: -50px;
  bottom: -40% !important;
  right: 78%;
  /* Position the tooltip */
  position: absolute !important;
  z-index: 1500;

  /* overflow: auto; */
}

.righThirdtOutlined::after {
  content: "";
  position: absolute;
  top: 45%;
  left: -6%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.containers:hover .righThirdtOutlinedHover {
  display: block;
  width: fit-content;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 5px;
  font-size: 00.875rem;

  /* Position the tooltip */
  position: absolute !important;
  bottom: -40% !important;
  left: 80%;
  z-index: 9;
  /* overflow: auto; */
}

.containers:hover .righThirdtOutlinedHover::after {
  content: "";
  position: absolute;
  top: 45%;
  left: -4%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.righThirdtOutlinedHover {
  display: none;
}
/* 
  @media only screen and (max-width: 1500px) { 
    .containers:hover .righSecondtOutlinedHover::after{
        content: "";
        position: absolute;
        bottom: 30% !important;
        left: -5% !important;
        border-width: 8px;
        border-style: solid;
        border-color:  transparent gray transparent transparent;
      }
    
  } */

@media only screen and (min-width: 1501px) {
  .containers:hover .righSecondtOutlinedHover::after {
    content: "";
    position: absolute;
    bottom: 30% !important;
    left: -3.5% !important;
    border-width: 8px;
    border-style: solid;
    border-color: transparent gray transparent transparent;
  }
}

@media only screen and (min-width: 1501px) {
  .containers:hover .financialTopTextBoxHover::after {
    content: "";
    position: absolute;
    bottom: 45% !important;
    left: -3.5% !important;
    border-width: 8px;
    border-style: solid;
    border-color: transparent gray transparent transparent;
  }
}

.moveTopOutlineTextBox {
  width: 300% !important;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px 5px;
  font-size: 00.875rem;
  margin-top: 25% !important;
  /* bottom: -135% !important; */
  /* Position the tooltip */
  position: absolute;
  z-index: 1500;
}

.moveTopOutlineTextBox::after {
  content: "";
  /* margin-top: -6% !important; */
  position: absolute;
  top: -25%;
  left: 5%;
  /*margin-left: 2px; */
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

.moveTopOutlineTextBoxHover {
  display: none;
}
.containers:hover .moveTopOutlineTextBoxHover {
  display: block;
  width: 300% !important;
  background-color: gray;
  color: #fff;
  text-align: left;
  border-radius: 0px;
  padding: 8px;
  font-size: 00.875rem;

  position: absolute;
  margin-top: 25% !important;
  z-index: 9;
}
.containers:hover .moveTopOutlineTextBoxHover::after {
  content: "";
  position: absolute;
  top: -30%;
  left: 5%;
  /* margin-left: 2px; */
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

@media only screen and (max-width: 1501px) {
  .financialTopTextBox::after {
    content: "";
    position: absolute;
    top: 60%;
    left: -6%;
    margin-left: 2px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent gray transparent transparent;
  }
}

@media only screen and (max-width: 1501px) {
  .righSecondtOutlined::after {
    content: "";
    position: absolute;
    bottom: 30% !important;
    left: -5% !important;
    border-width: 8px;
    border-style: solid;
    border-color: transparent gray transparent transparent;
  }
}

.righSecondtOutlinedNum {
  display: block;
  width: 100%;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px;
  font-size: 00.875rem;

  position: absolute !important;
  bottom: -30% !important;
  margin-left: 220px !important;
  z-index: 9;
}

.righSecondtOutlinedNum::after {
  content: "";
  position: absolute;
  bottom: 30% !important;
  left: -7% !important;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.containers:hover .righSecondtOutlinedNumHover {
  display: block;
  width: 100%;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px;
  font-size: 00.875rem;

  position: absolute !important;
  bottom: -5% !important;
  margin-left: 220px !important;
  z-index: 9;
}

.containers:hover .righSecondtOutlinedNumHover::after {
  content: "";
  position: absolute;
  bottom: 20% !important;
  left: -7% !important;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.righSecondtOutlinedNumHover {
  display: none;
}

@media only screen and (min-width: 1501px) {
  .containers:hover .righSecondtOutlinedNumHover::after {
    content: "";
    position: absolute;
    bottom: 20% !important;
    left: -8% !important;
    border-width: 8px;
    border-style: solid;
    border-color: transparent gray transparent transparent;
  }
}
@media only screen and (max-width: 1501px) {
  .righSecondtOutlinedNum::after {
    content: "";
    position: absolute;
    bottom: 30% !important;
    left: -7% !important;
    border-width: 8px;
    border-style: solid;
    border-color: transparent gray transparent transparent;
  }
}

.sixthrighSecondtOutlined {
  display: block;
  width: auto;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px;
  font-size: 00.875rem;

  position: absolute !important;
  bottom: -5% !important;
  margin-left: 220px !important;
  z-index: 9;
}

.sixthrighSecondtOutlined::after {
  content: "";
  position: absolute;
  bottom: 30% !important;
  left: -2.5% !important;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.containers:hover .sixthrighSecondtOutlinedHover {
  display: block;
  width: auto;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px;
  font-size: 00.875rem;

  position: absolute !important;
  bottom: -3% !important;
  margin-left: 220px !important;
  z-index: 9;
}

.containers:hover .sixthrighSecondtOutlinedHover::after {
  content: "";
  position: absolute;
  bottom: 22% !important;
  left: -4% !important;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.sixthrighSecondtOutlinedHover {
  display: none;
}

@media only screen and (min-width: 1501px) {
  .containers:hover .sixthrighSecondtOutlinedHover::after {
    content: "";
    position: absolute;
    bottom: 30% !important;
    left: -2.5% !important;
    border-width: 8px;
    border-style: solid;
    border-color: transparent gray transparent transparent;
  }
}
@media only screen and (max-width: 1501px) {
  .sixthrighSecondtOutlined::after {
    content: "";
    position: absolute;
    bottom: 22% !important;
    left: -4% !important;
    border-width: 8px;
    border-style: solid;
    border-color: transparent gray transparent transparent;
  }
}

.topTextBoxFinancial {
  width: 50%;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 0px 5px !important;
  font-size: 00.875rem;
  top: 103% !important;
  position: absolute;
  z-index: 1500;
  left: 50%;
}

.topTextBoxFinancial::after {
  content: "";
  position: absolute;
  top: -19%;
  left: 90%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}
.topTextBoxFinancialHover {
  display: none;
}
.containers:hover .topTextBoxFinancialHover {
  display: block;
  width: 50%;
  background-color: gray;
  color: #fff;
  text-align: left;
  border-radius: 0px;
  padding: 0px 5px;
  /* padding-top: 2px !important; */
  font-size: 00.875rem;

  /* Position the tooltip */
  position: absolute;
  top: 110% !important;
  z-index: 9;
  left: 50%;
  /* overflow: auto; */
}
.containers:hover .topTextBoxFinancialHover::after {
  content: "";
  position: absolute;
  top: -18% !important;
  left: 90%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent gray transparent;
}

@media only screen and (min-width: 1501px) {
  .topTextBoxFinancial::after {
    content: "";
    position: absolute;
    top: -25% !important;
    left: 90% !important;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent gray transparent;
  }
}

.fintextAreaTooltipLast {
  width: 500px;
  background-color: gray;
  color: #fff;
  text-align: left;
  /* border-radius: 6px; */
  padding: 5px 5px;
  font-size: 00.875rem;
  margin-top: -50px;
  bottom: 118% !important;
  left: 20%;
  /* Position the tooltip */
  position: absolute !important;
  z-index: 1500;

  /* overflow: auto; */
}

.fintextAreaTooltipLast::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}

.fintextAreaTooltipLastHover {
  display: none;
}
.containers:hover .fintextAreaTooltipLastHover {
  display: block;
  width: 60%;
  background-color: gray;
  color: #fff;
  text-align: left;
  border-radius: 0px;
  padding: 8px;
  font-size: 00.875rem;
  left: 30%;
  position: absolute;
  bottom: 125% !important;
  z-index: 9;
}
.containers:hover .fintextAreaTooltipLastHover::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 5%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: gray transparent transparent transparent;
}
@media only screen and (min-width: 1501px) {
  .fintextAreaTooltipLast {
    width: 500px;
    background-color: gray;
    color: #fff;
    text-align: left;
    /* border-radius: 6px; */
    padding: 5px 5px;
    font-size: 00.875rem;
    margin-top: -50px;
    bottom: 120% !important;
    left: 20%;
    /* Position the tooltip */
    position: absolute !important;
    z-index: 1500;

    /* overflow: auto; */
  }
}
.progTopTextBox {
  display: block;
  width: 100%;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px;
  font-size: 00.875rem;

  position: absolute !important;
  bottom: -50% !important;
  margin-left: 220px !important;
  z-index: 9;
}

.progTopTextBox::after {
  content: "";
  position: absolute;
  top: 45%;
  left: -7%;
  margin-left: 2px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.progTopTextBoxHover {
  display: none;
}
.containers:hover .progTopTextBoxHover {
  display: block;
  width: 100%;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px;
  font-size: 00.875rem;

  position: absolute !important;
  bottom: -50% !important;
  margin-left: 220px !important;
  z-index: 9;
}
.containers:hover .progTopTextBoxHover::after {
  content: "";
  position: absolute;
  bottom: 40% !important;
  left: -7% !important;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

@media only screen and (min-width: 1501px) {
  .containers:hover .progTopTextBoxHover::after {
    content: "";
    position: absolute;
    bottom: 40% !important;
    left: -7% !important;
    border-width: 8px;
    border-style: solid;
    border-color: transparent gray transparent transparent;
  }
}
@media only screen and (max-width: 1501px) {
  .progTopTextBox::after {
    content: "";
    position: absolute;
    top: 43%;
    left: -8%;
    margin-left: 2px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent gray transparent transparent;
  }
}

@media only screen and (max-width: 1854px) {
  .errorCount {
    z-index: 400000;
    color: "white";
    position: "absolute";
    left: "30.3%";
    top: "0px";
    display: "flex";
    background-color: "black";
    width: "655px";
    height: "40px";
    align-items: "center";
    padding-left: "10px";
  }
}

@media only screen and (min-width: 1854px) {
  .errorCount {
    z-index: 400000;
    color: "white";
    position: "absolute";
    left: "32.3%";
    top: "0px";
    display: "flex";
    background-color: "black";
    width: "655px";
    height: "40px";
    align-items: "center";
    padding-left: "10px";
  }
}

#errorCount {
  z-index: 400000;
  color: "white";
  position: "absolute";
  left: "32.3%";
  top: "0px";
  display: "flex";
  background-color: "black";
  width: "655px";
  height: "40px";
  align-items: "center";
  padding-left: "10px";
}

.loanactNEW label {
  color: rgba(0, 0, 0, 0.54) !important;
}
.loanactNEW .MuiInput-underline.Mui-error:after {
  /* border-bottom-color: rgba(0, 0, 0, 0.54) !important; */
  border-bottom: 1px solid rgba(204, 195, 195, 0.54);
}

.loanactNEWFocus .MuiInput-underline.Mui-error:after {
  border-bottom: 2px solid #36aebe;
}

.norPUB {
  width: 100%;
}

.norPUB label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.norPUB .MuiInput-underline.Mui-error:after {
  /* border-bottom-color: rgba(0, 0, 0, 0.54) !important; */
  border-bottom: 1px solid rgba(204, 195, 195, 0.54);
}

.explorePreview .scroll-table-full-width {
  height: calc(100vh - 345px) !important;
}

.financialFull p.Mui-error {
  position: absolute !important;
  top: 90% !important;
}
.financialFull .outlineMulti p.Mui-error {
  position: absolute !important;
  top: 100% !important;
}

.programACT p.Mui-error {
  position: absolute !important;
  top: 90% !important;
}

.outlineMulti textarea:first-child {
  font-size: 0.875rem !important;
}

.MuiInputBase-multiline textarea:first-child.Mui-disabled {
  color: rgba(0, 0, 0, 0.35) !important;
}

.CustomOutlineText input {
  color: rgba(0, 0, 0, 0.75) !important;
}

.modifiedNumberField input {
  color: rgba(0, 0, 0, 0.75) !important;
}
.errorClassBG {
  background-color: #ea4633 !important;
}
.warningClassBG {
  background-color: #f9c128 !important;
}
.contractRootEdit {
  overflow: visible !important;
}
.cliamedChipDiv {
  text-align: center;
}
.cliamedChip {
  margin: 10px 8px;
}
.dashBoardConts,
.dashreport {
  padding: 0px 8px !important;
}
.dashBoardTopLtTitle {
  padding: 6px 0px 2px !important;
}
.dashBoardTopRtTitle {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  padding: 6px 0px 2px !important;
}
.defaultReportingForms {
  display: flex;
  flex-direction: row;
}
.ReportDownloadBox {
  background-color: #f4f4f4 !important;
}
.textRight {
  text-align: right;
}
.textCenter {
  text-align: center;
}
.loanCardRateContent {
  display: flex;
  justify-content: center;
}
.loanCardRateContentHead {
  padding-bottom: 10%;
}
.exploreMenuContainer,
.filterAttriContainer,
.ExpPreviewContainer {
  display: flex;
  justify-content: flex-end;
  align-self: center;
  padding: 0;
}
.sectionTitleMargin {
  margin: 30px 0px 12px;
}
.helpContainerShadow {
  box-shadow: 0px 0px 2px grey;
}
.helpPageContent {
  background-color: #fff;
  padding: 16px;
  overflow: auto;
}
.uploadfile {
  height: 100px;
  line-height: 100px;
}
.importFilesContainer {
  width: 100%;
  margin: 0 16px;
}
.filesListATag {
  color: #36aebe;
  cursor: pointer;
}
.contractActivitySecTitle,
.certificationSecTitle,
.reportRecovSecTitle {
  margin: 12px 0px !important;
}
.loanProjGeneralContainer {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 192px);
}
.houseHoldTotalIncome {
  text-align: right;
  margin-right: 8px;
  font-weight: bold;
}
.holuseHoldEditableContainer {
  margin: 16px 0px;
}
.houseHoldImputedIncome {
  text-align: right;
  font-weight: bold;
}
.projTabsGeneralRoot {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 200px);
}
.contactUser .usersEditRoot {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 221px);
}
.textLeft {
  text-align: left !important;
}
.draggableEditTabNoRec {
  font-size: 14px;
  color: #ccc;
}
.draggableEditTableRow {
  padding: 10px;
  text-align: center;
}
.helpFloaterBtnContainer {
  text-align: center;
  padding: 12px 0;
}
.required-header.finanfour {
  width: 95% !important;
}
.contractactivityListtop .parentCard {
  overflow: hidden !important;
}
.autoCompleteTop .MuiInput-underline {
  margin-top: 15px !important;
}
.MuiTextField-root .MuiInput-underline::after {
  border-bottom: 2px solid #36aebe;
}
/* .autoCompleteTopsy .MuiInputBase-input {
    margin-top: 5px !important;
} */
.displayFlex {
  display: flex;
}
.alignItemsCenter {
  align-items: center;
}

/* progam acctivity tooltip */

.righProgSecondtOutlinedNum {
  display: block;
  width: 100%;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px;
  font-size: 00.875rem;

  position: absolute !important;
  bottom: -30% !important;
  margin-left: 220px !important;
  z-index: 9;
}

.righProgSecondtOutlinedNum::after {
  content: "";
  position: absolute;
  bottom: 20% !important;
  left: -7% !important;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.containers:hover .righProgSecondtOutlinedNumHover {
  display: block;
  width: 100%;
  background-color: gray;
  color: #fff;
  text-align: left;
  padding: 5px;
  font-size: 00.875rem;

  position: absolute !important;
  bottom: -5% !important;
  margin-left: 220px !important;
  z-index: 9;
}

.containers:hover .righProgSecondtOutlinedNumHover::after {
  content: "";
  position: absolute;
  bottom: 10% !important;
  left: -7% !important;
  border-width: 8px;
  border-style: solid;
  border-color: transparent gray transparent transparent;
}

.righProgSecondtOutlinedNumHover {
  display: none;
}

.HTFContractActivity {
  padding-left: 1%;
}

@media only screen and (min-width: 1501px) {
  .containers:hover .righProgSecondtOutlinedNumHover::after {
    content: "";
    position: absolute;
    bottom: 10% !important;
    left: -7% !important;
    border-width: 8px;
    border-style: solid;
    border-color: transparent gray transparent transparent;
  }
}
@media only screen and (max-width: 1501px) {
  .righProgSecondtOutlinedNum::after {
    content: "";
    position: absolute;
    bottom: 20% !important;
    left: -7% !important;
    border-width: 8px;
    border-style: solid;
    border-color: transparent gray transparent transparent;
  }
}

.uploadphoto {
  word-wrap: break-word;
  width: 100%;
  line-height: 20px;
}

.MuiOutlinedInput-notchedOutline span {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.unitsBulkUpdate .autocomplete-bordered-material-table {
  width: 100% !important;
}

.conditionDropdown {
  width: 100%;
}

.MuiSelect-select {
  height: auto !important;
  overflow: hidden !important;
  min-height: 1.1876em !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  padding: 5px 24px 5px 3px !important;
  font-size: 0.875rem !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #36aebe !important;
}

.autocomplete-fixeds {
  margin-top: 0px !important;
}

.autocomplete-fixed.dotted .MuiInput-root.MuiInput-underline::before {
  border-bottom: 1px dotted #ddd !important;
}
.toorgmerges.MuiGrid-root.MuiGrid-item {
  margin-top: -9px;
}

.funderEditDatePicker {
  width: 100%;
}

.autocomplete-fixedTopOCN {
  margin-top: 2px;
}

.funderTaxTable .MuiTableRow-root {
  height: 40px;
}

.funderTaxTable .autocompleteFix {
  margin-bottom: 3px;
}

.CustomOutlineText.MuiFormControl-marginDense {
  margin-top: 4px !important;
}

.certfAddressCity .autocomplete-fixed {
  margin-top: 7px !important;
}

/* .autocomplete-fixed{
    margin-top: 2px !important;
} */

.selectClaimedSite .autocomplete-fixed {
  margin-top: 8px;
}

.autoCompleteTop .autocomplete-fixed {
  margin-top: 8px;
}

.autocomple_dropdwn {
  background-color: white !important;
  padding-bottom: 21px;
}

.unitbulkautocomplete {
  background-color: #eeeeee !important;
  padding-bottom: 21px;
}

.ReportDownloadBox .autocomple_dropdwn {
  background-color: #f4f4f4 !important;
  width: 12px !important;
  padding-right: 9px !important;
  margin-right: 0px !important;
}

.css-26l3qy-menu {
  min-width: 200px !important;
}

.textBox--bordered.txt-right {
  width: 100% !important;
}

.SARD.m-t-3 {
  margin-top: 3px !important;
}

.SARD.m-t-0 {
  margin-top: 0px;
}

.SARD.m-t--3 {
  margin-top: -3px !important;
}

.SARD.m-t--6 {
  margin-top: -6px !important;
}

.contPreparerOrg {
  margin-top: -12px !important;
}

.textBox--bordered {
  width: 100% !important;
}

.builingZip.m-t-2 {
  margin-top: 2px !important;
}
/* .builingZip.m-t--1{
    margin-top: -1px !important;
} */

.configurationTable .MuiInput-input {
  padding: 7px 0 7px 4px !important;
  letter-spacing: 0 !important;
}

/* .MuiInputBase-multiline, .textBox--bordered .MuiInputBase-multiline {
        padding:5px !important;
    } */

.textBox--bordered textarea {
  padding: 3px !important;
}

.buildeffectiveyearbuilt {
  margin-top: 7px !important;
}

.funderEditFirstReport input {
  margin-top: -5px !important;
}

.occuhouseholdType_id {
  margin-top: 3px !important;
}

.household-info-table .autocomple_dropdwn {
  width: 4px !important;
}

.uacustom .autocomple_dropdwn {
  width: 5px !important;
  margin-right: 3px !important;
}

.usersEditRoot .autocomplete-fixeds {
  margin-top: 4px !important;
}
.usersEditRoot .autocomplete-fixedsStatus {
  margin-top: -6px !important;
}
.usersEditRoot .autocomplete-fixedsCity {
  margin-top: -6px !important;
}

.multiAccessmarginFix {
  margin-top: 16px !important;
}

.userStateEdit {
  margin-top: -7px !important;
}

.autocomplete-fixedOrgType {
  margin-top: 6px !important;
}

.autocompleteStateFix {
  margin-top: 4px !important;
}

.filterexplorePreview .autocomple_dropdwn {
  left: 90% !important;
  padding-left: 0px !important;
  width: 20px !important;
  padding-right: 7px !important;
}

.exploreColumnFilter .autocomple_dropdwn {
  left: 90% !important;
}

.autocomplete-fixed-report-type {
  margin-top: 8px !important;
}

.filterexplorePreview {
  overflow: scroll !important;
}

.certfAddressCity .autocomple_dropdwn {
  top: 6px !important;
}

.SnackbarContainer-top {
  margin-top: 80px;
}

.fixOverlapContractor .autocomple_dropdwn {
  margin-right: 0px !important;
}

.organization_type .MuiFormLabel-root.Mui-error {
  color: #d32f2f;
}

.organization_type .MuiInput-underline.Mui-error:after {
  transform: scaleX(1);
  border-bottom-color: #d32f2f;
}

.organization_type .MuiFormHelperText-root.Mui-error {
  color: #d32f2f;
}

.complianceContainer {
  overflow: auto;
}

.customMenu .MuiListItem-button:hover {
  color: #36aebe !important;
}

.DashboardReports {
  height: calc(100vh + -215px) !important;
}

.assignFunder_dropdown .MuiDialog-paperScrollPaper {
  overflow: visible !important;
  min-height: 70%;
}

.previousCertDateCSS {
  margin-top: 0px !important;
  padding-top: 8px !important;
}

.contPreparerOrg .autocomple_dropdwn {
  margin-right: 0px !important;
}

.xmlZoomClass {
  margin-left: 57px !important;
}

.importYearXml {
  margin-top: 3px !important;
}

.xml-codemirror {
  height: 100%;
}

.xmlWarning svg {
  margin-top: 3px;
  margin-right: 5%;
}

.table1LogDisable .MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: #646262bf !important;
}

.listAbsolute {
  position: relative;
  background-color: #797a7c;
}

#simple-popper {
  z-index: 99;
}

.tableClickTextBox {
  width: 120px;
  /* height: fit-content; */
  max-height: 170px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  padding: 5px;
  font-size: 00.875rem;
  margin-top: -50px;
  top: calc(100% - 45px);
  left: 75%;
  position: absolute !important;
  z-index: 1500;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow: auto;
  /* line-height: 100px !important; */
}

.tableClickTextBox li {
  padding-bottom: 6px;
}

.StyledBadgeClass > span:hover {
  cursor: pointer;
}

.StyledBadgeClass {
  left: 57%;
  bottom: 1vh;
}

.lastList.tableClickTextBox {
  width: 120px;
  /* height: fit-content; */
  max-height: 250px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  text-align: left;
  padding: 5px;
  font-size: 00.875rem;
  margin-top: -50px;
  /* bottom: calc(100% - 45px);     */
  bottom: 30%;
  left: 75%;
  position: absolute !important;
  z-index: 1500;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow: auto;
  /* line-height: 100px !important; */
}

.loanContractItem > span:hover {
  color: #36aebe;
}

.StyledBadgeClass .MuiBadge-badge {
  border-radius: 50% !important;
}

.tableClickTextBox .MuiListItem-gutters {
  padding-left: 12px !important;
}

.loanContractItem .MuiListItemText-primary {
  font-size: 13px !important;
}

.contractListTabRoot .MuiTypography-body1 {
  font-size: 13px !important;
}

.css-1rnmjiu-MuiAutocomplete-root
  .MuiOutlinedInput-root
  .MuiAutocomplete-input {
  height: 2px;
  margin-top: 0px;
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  padding-top: 3px !important;
}

.css-170p5b9-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

.moveOutReason{
  width: 100%;
}



