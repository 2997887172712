@charset "UTF-8";

@font-face {
  font-family: "custom-font";
  src:url("../fonts/custom-font.eot");
  src:url("../fonts/custom-font.eot?#iefix") format("embedded-opentype"),
    url("../fonts/custom-font.woff") format("woff"),
    url("../fonts/custom-font.ttf") format("truetype"),
    url("../fonts/custom-font.svg#custom-font") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "custom-font" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "custom-font" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-claim:before {
  content: "\61";
}
.icon-unclaim:before {
  content: "\62";
}
.icon-xml:before {
  content: "\63";
}
.icon-merge-2:before {
  content: "\64";
}
.icon-dashboard-1:before {
  content: "\65";
}
.icon-help:before {
  content: "\66";
}
.icon-family:before {
  content: "\67";
}
.icon-apartments:before {
  content: "\68";
}
.icon-cityscape-2:before {
  content: "\69";
}
.icon-house-2:before {
  content: "\6a";
}
.icon-house-3:before {
  content: "\6b";
}
.icon-school-house:before {
  content: "\6c";
}
.icon-three-buildings-1:before {
  content: "\6d";
}
.icon-selected-menu:before {
  content: "\6e";
}
.icon-filter-1:before {
  content: "\6f";
}
.icon-filter:before {
  content: "\70";
}
.icon-building-4:before {
  content: "\71";
}
