.badgeMedium .MuiBadge-badge {
  height: 21px;
  width: 21px;
  border-radius: 21px;
}
.p-l-18 {
  padding-left: 18px;
}
.activeTab .MuiBadge-colorPrimary {
  color: #fff;
  background-color: #36aebe;
}
.inactiveTab .MuiBadge-colorPrimary {
  color: #fff;
  background-color: #747879;
}
.p-0-14 {
  padding: 0px 14px;
}
.p-10-8-0 {
  padding: 10px 8px 0;
}
.displayFlex {
  display: flex;
}
.alignItemsCenter {
  align-items: center;
}
.alignSelfCenter {
  align-self: center;
}
.alignContentSpaceAround {
  align-content: space-around;
}
.alignContentCenter {
  align-content: center;
}
.alignContentSB{
  align-content: space-between;
}
.jcCenter {
  justify-content: center;
}
.jcSpaceBetween {
  justify-content: space-between;
}
.whiteBG {
  background: #fff;
}
.MuiTab-root {
  min-height: 46px !important;
}
.hw-20 {
  height: 20px;
  width: 20px;
}
.w-100-per {
  width: 100%;
}
.fw-600 {
  font-weight: bold !important;
}
.pt-85 {
  padding-top: 85px !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pt-36{
  padding-top: 40px !important;
}
.lineHeit {
  line-height: 1.8em !important;
}
.pl-12 {
  padding-left: 12px !important;
}
.p-b-32 {
  padding-bottom: 32px;
}
.max-h-110{
  max-height: 110px;
}
.max-h-200{
  max-height: 200px;
}
.imgFont {
  font-size: 20px !important;
  color: #36aebe;
}
.uploadArea {
  position: relative;
  width: 100%;
  height: 255px;
  border-width: 2px;
  border-color: #bcafaf;
  border-style: dashed;
  background-color: #f1f0f0;
  text-align: center;
  line-height: 200px;
  font-size: 15px;
  letter-spacing: 0;
}
.height-255 {
  height: 255px;
}
.pA-10 {
  padding: 10px;
}
.pA-24 {
  padding: 24px;
}

/* .MuiDialogActions-root {
  justify-content: center !important;
} */
.textLeft {
  text-align: left;
}
.p-t-b-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.p-t-32 {
  padding-top: 32px;
}
.p-l-r-180 {
  padding-left: 180px;
  padding-right: 180px;
}
.p-l-r-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.p-10-8-8-8{
  padding: 10px 8px 8px 8px;
}
.p-6-8-4-8{
  padding: 6px 8px 4px 8px;
}
.marginTop-32 {
  margin-top: 32px !important;
}
.width-100-per {
  width: 100% !important;
}
.height-100-per {
  height: 100%;
}
.overFlowAuto {
  overflow: auto;
}
.help-icon {
  height: 21px;
  width: 19px;
  font-size: 18px !important;
  color: #36aebe;
}
.textCenter {
  text-align: center;
}
.textLeft {
  text-align: left;
}
.bg-red {
  background-color: #fcf0ef;
}
.color-red {
  color: #e64949;
}
.minWidth-30-per {
  min-width: 30%;
}
.br-24 {
  border-radius: 24px;
}
.cursorPointer {
  cursor: pointer;
}
.files-list a:hover {
  border-bottom: 1px solid;
}

.textCenter {
  text-align: center;
}
.textItalic{
  font-style: italic;
}
.MuiOutlinedInput-input {
  padding: 12.5px 14px !important;
}